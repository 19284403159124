import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDashboardPVComponent } from '../modal-dashboard-pv/modal-dashboard-pv.component';

@Component({
  selector: 'app-modal-dashboard-tabular',
  templateUrl: './modal-dashboard-tabular.component.html',
  styleUrls: ['./modal-dashboard-tabular.component.css']
})
export class ModalDashboardTabularComponent implements OnInit {
  @Input() public tableData:Array<any>;
  @Input() public headerColumns: Array<string>;
  @Input() public gdTitle: any;

  constructor(public activeModal:NgbActiveModal) { }

  ngOnInit() {
  }

}
