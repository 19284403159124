import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../../shared/global';
import { Observable, observable } from 'rxjs';
import { Supplier } from './supplierForInsertion';
import { Headers, Http, Response } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class SupplierServiceService {
  _global: Global;
  //userDetails = JSON.parse(localStorage.getItem('userData'));
  public userDetails: any;
  constructor(private httpcli: HttpClient, private global: Global, private http: Http) {
    this._global = global;
  }

  public serviceGetSupplierDetailsBySupplierID(supplierID: number): Observable<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.userDetails.accessToken)
      .set('UserID', this.userDetails.userId);

    return this.httpcli.get<any>("" + this._global.apiBaseURl + "Supplier/GetTheDetailsBySupplierID?supplierID=" + supplierID + "",
      { headers: requestheaders });
  }

  public serveManageSupplier(supplierDetails: Supplier): Observable<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.userDetails.accessToken)
      .set('UserID', this.userDetails.userId);

    return this.httpcli.post<any>("" + this._global.apiBaseURl + "Supplier/ManageSupplier", supplierDetails,
      { headers: requestheaders });
  }

  public serviceFillControls(): Observable<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.userDetails.accessToken)
      .set('UserID', this.userDetails.userId);

    return this.httpcli.get<any>("" + this._global.apiBaseURl + "Supplier/FillTheControlsForTheSupplierPage",
      { headers: requestheaders });
  }

  //Added by Mahesh on 18-11-2019
  //To get supplier ports list
  public getSupplierPorts(selectedCountryId, supplierId): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Supplier/GetSupplierPortsList?SelectedCountryId=" + selectedCountryId + "&SupplierId=" + supplierId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Dnyaneshwari
  public getCountryPortList(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Supplier/GetCountryPortList", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Mahesh on 07-04-2020
  public deleteSupplierPort(port): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.post("" + this._global.apiBaseURl + "Supplier/DeleteSupplierPort", port, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Mahesh on 04-05-2020
  public deleteSupplierContactDetail(contactDetailsId): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Supplier/DeleteSupplierContactDetail?SupplierContactDetailsId=" + contactDetailsId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Mahesh on 24-05-2020
  //To get the list of ports / origins
  public getPortOriginList(CountryId: any): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Supplier/GetPortOriginList?CountryId=" + CountryId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }
}
