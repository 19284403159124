import { Component, OnInit } from '@angular/core';
import { ModalWindowService } from '../../../shared/modal';
import { SupplierInternalUserService } from './Shared/supplier-internal-user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TUser } from '../../../my-profile/Customer';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { InternaluserService } from '../../internaluser/Shared/internaluser.service';
import { InternalUser } from '../../internaluser/user';
import { NgForm } from '@angular/forms'
import { FormlabelsService } from '../../../shared/formlabels.service';

@Component({
  selector: 'app-supplier-internal-user',
  templateUrl: './supplier-internal-user.component.html',
  styleUrls: ['./supplier-internal-user.component.css']
})
export class SupplierInternalUserComponent implements OnInit {
  //public userDetails = JSON.parse(localStorage.getItem('userData'));
  public userDetails: any;
  public supplierInternalUserListForDisplay: any;
  public dtOptions: DataTables.Settings = {};
  public loading: boolean = false;
  public showAllUserButton: boolean = false;
  public user: any;
  public isStatusDropdownShown: boolean = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public disableEmailFeild: boolean = true;
  public roleDetails: any = [];
  public statusDetails: any;
  public Operation: string;
  public internalUser: any;
  deleteUserId: any;
  public pageLabels: any = [];

  constructor(private modalService: ModalWindowService,
    private supplierInternalUserService: SupplierInternalUserService,
    private route: Router, private toastr: ToastrService,
    private internalUserService: InternaluserService,
    private formLabelService: FormlabelsService) { }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    if (this.userDetails == null) {
      this.route.navigate(['/']);
      return;
    } else if (this.userDetails != null && this.userDetails.userStatusCfgId == 1) {
      this.route.navigate(['/login']);
    }
    else {
      this.user = new TUser();
      this.internalUser = new InternalUser();
      this.getRoleMaster();
      this.getStatusDetail();

      this.dtOptions = {
        data: this.supplierInternalUserListForDisplay,
        pagingType: 'full_numbers',
        scrollY: '130px',
        scrollX: true
      };
      this.getSupplierInternalUserDetails();
    }
    this.getAllLabelsAndMessages();
  }

  //When adding new internal user to show modal windows.
  openModal(id: string, Obj: NgForm) {
    this.Operation = "Add";
    // this.user = new TUser();
    Obj.resetForm();
    this.modalService.open(id);
    if (this.disableEmailFeild == false) {
      this.disableEmailFeild = true;
    }
    if (this.isStatusDropdownShown == true) {
      this.isStatusDropdownShown = false;
    }
  }
  //Close modal popup.
  closeModal(id: string) {
    this.modalService.close(id);
  }

  //Added by Mahesh on 17-10-2019
  //To show all intenal users including deleted users except logged in user.
  async showAllUsersExceptLoggedIn() {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.supplierInternalUserService.showAllUsersExceptLoggedIn(this.userDetails).subscribe((response: any) => {

      if (response.isSuccessful == true) {
        console.log(response.data);
        this.supplierInternalUserListForDisplay = response.data;
      }
      else if (response.isSuccessful == false && response.consoleMessage == "UNAUTH") {
        this.toastr.error(response.message, "", { timeOut: 5000 });
        localStorage.removeItem('userData');
        this.route.navigate(['/']);
      } else if (response.isSuccessful == false) {
        this.toastr.error(response.message, "", { timeOut: 5000 });
      } else {
        this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
      }
    });
  }

  //Added by Mahesh on 17-10-2019
  //To show all intenal users except deleted users and logged in user.
  async getSupplierInternalUserDetails() {
    this.loading = true;
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let isSupplier = true;
    this.supplierInternalUserService.getSupplierInternalUserDetails(this.userDetails.userId, isSupplier)
      .then((response: any) => {
        if (response.isSuccessful == true) {
          this.supplierInternalUserListForDisplay = response.data.supplierInternalUserList;
          this.showAllUserButton = response.data.showAllUserButton;
          this.loading = false;
        }
        else if (response.isSuccessful == false && response.consoleMessage == "UNAUTH") {
          this.toastr.error(response.message, "", { timeOut: 5000 });
          this.loading = false;
          localStorage.removeItem('userData');
          this.route.navigate(['/']);
        }
        else if (response.isSuccessful == false) {
          this.toastr.error(this.pageLabels[707], "", { timeOut: 5000 });//No records found to display.[707]
          this.loading = false;
        }
        else {
          this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          this.loading = false;
        }
      }).catch((error) => {
        this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
        this.loading = false;
      })
  }

  //Added by Mahesh on 17-10-2019
  //To add new supplier internal user.
  async AddSupplierInternalUser() {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.loading = true;
    this.user.baseUrl = window.location.protocol + "//" + window.location.host;
    this.user.loggedInUserId = this.userDetails.userId;
    this.supplierInternalUserService.addSupplierInternalUser(this.user)
      .then((result: any) => {
        if (result.isSuccessful == true) {

          this.toastr.success(result.message, "", { timeOut: 5000 });
          this.modalService.close('custom-modal-1');
          this.getSupplierInternalUserDetails();
          this.loading = false;
          window.location.reload();
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.toastr.error(result.message, "", { timeOut: 5000 });
          this.loading = false;
          localStorage.removeItem('userData');
          this.route.navigate(['/']);
        } else if (result.isSuccessful == false) {
          this.toastr.error(result.message, "", { timeOut: 5000 });
          this.loading = false;
        } else {
          this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          this.loading = false;
        }
      }).catch((result) => {
        this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
        this.loading = false;
      })
  }

  //Added by Mahesh on 17-10-2019
  //To get and bind the supplier internal user details to modal popup for edit.
  openModalAndBindValue(id: string, userId: number) {
    this.Operation = "Update";
    this.modalService.open(id);
    this.getInternalUserDetailsForEdit(userId);
    if (this.disableEmailFeild == true) {
      this.disableEmailFeild = false;
    }
    if (this.isStatusDropdownShown == false) {
      this.isStatusDropdownShown = true;
    }
  }

  //Added by Mahesh on 17-10-2019
  //To save the edited supplier internal user details.
  async getInternalUserDetailsForEdit(userId) {
    this.internalUserService.getInternalUserDetailsForEdit(userId)
      .then((result) => {

        if (result.isSuccessful == true) {
          this.user = result.data;
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.toastr.error(result.message, "", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this.route.navigate(['/']);
        } else if (result.isSuccessful == false) {
          this.toastr.error(result.message, "", { timeOut: 5000 });
        } else {
          this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          this.loading = false;
        }
      }).catch((result) => {
        this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]

      })
  }

  //Added by Mahesh on 17-10-2019
  //To pass the internal user id to deleteSupplierInternalUser() function to delete.
  async getDeleteUserIdAndOpenConfirmPopUp(userId) {
    this.deleteUserId = userId;
  }

  //Added by Mahesh on 17-10-2019
  //To Update Intenal User Status.
  async updateSupplierInternalUserStatus(userId) {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.internalUser.userId = userId;
    this.internalUser.loggedInUserId = this.userDetails.userId;
    this.internalUserService.enableInternalUser(this.internalUser)
      .then((result) => {
        if (result.isSuccessful == true) {
          this.getSupplierInternalUserDetails();
          this.toastr.success(result.message, "", { timeOut: 5000 });
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.toastr.error(result.message, "", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this.route.navigate(['/']);
        }
        else if (result.isSuccessful == false) {
          this.toastr.error(result.message, "", { timeOut: 5000 });
        }
        else {
          this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
        }
      }).catch((result) => {
        this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
      });
  }

  //Added by Mahesh on 17-10-2019
  //To Delete Supplier Internal User.
  async deleteSupplierInternalUser(userId) {
    this.internalUser.userId = userId;
    this.supplierInternalUserService.deleteSupplierInternalUser(this.internalUser)
      .then((result) => {

        if (result.isSuccessful == true) {
          $("#confirmDeleteUser .close").click()
          this.toastr.success(result.message, "", { timeOut: 5000 });
          this.getSupplierInternalUserDetails();
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.toastr.error(result.message, "", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this.route.navigate(['/']);
        } else if (result.isSuccessful == false) {
          this.toastr.error(result.message, "", { timeOut: 5000 });
        } else {
          this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]

        }
      }).catch((result) => {
        this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]

      })
  }

  //Added by Mahesh on 17-10-2019
  //To get Supplier Internal User roles.
  async getRoleMaster() {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let userId: number = this.userDetails.userId;
    this.internalUserService.getUserRole(userId)
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.roleDetails = result.data;
          }
          else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
            this.toastr.error(result.message, "", { timeOut: 5000 });
            localStorage.removeItem('userData');
            this.route.navigate(['/']);
          } else if (result.isSuccessful == false) {
            this.toastr.error(result.message, "", { timeOut: 5000 });
          } else {
            this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          }
        } catch (Error) {

        }
      })
  }

  //Added by Mahesh on 17-10-2019
  //To get Supplier Internal User status.
  async getStatusDetail() {
    this.internalUserService.getUserStatus()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.statusDetails = result.data;
          }
          else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
            this.toastr.error(result.message, "", { timeOut: 5000 });
            localStorage.removeItem('userData');
            this.route.navigate(['/']);
          } else if (result.isSuccessful == false) {
            this.toastr.error(result.message, "", { timeOut: 5000 });
          } else {
            this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          }
        } catch (Error) {

        }
      })
  }

  //added by kunal to get labels and messages from resources on 23/8/2020
  async getAllLabelsAndMessages() {
    this.formLabelService.getAllLabelsAndMessages()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.pageLabels = [];
            for (let i = 0; i <= result.data.length - 1; i++) {
              this.pageLabels.push(result.data[i].resourceValue);
            }
          }
          else if (result.isSuccessful == false) {
            this.toastr.error(result.message, "", { timeOut: 5000 });
          }
          else {
            this.toastr.error("Failed to connect to the server", "", { timeOut: 5000 });
          }
        } catch (Error) {

        }
      })
      .catch((err) => {

      })
  }
}
