import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { Global } from '../shared/global';

@Injectable({
  providedIn: 'root'
})
export class FormlabelsService {
  userDetails = JSON.parse(localStorage.getItem('userData'));
  public userdata: any;
  private requestUrl = "";
  private _global: Global;

  constructor(private http: Http, private global: Global) {
    this._global = global;
  }

  public getAllLabelsAndMessages(): Promise<any> {

    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.get("" + this._global.apiBaseURl + "FetchData/GetAllFormLabelsAndMessages", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }
}
