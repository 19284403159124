import { Component } from '@angular/core';
import { Global } from './shared/global';
import { Router } from '@angular/router';
import { AppConfigService } from './app-config.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading: boolean = false;
  constructor(private global: Global, private router: Router, private appConfig: AppConfigService) {
    global.role = "Shailesh PM";
    global.apiBaseURl = appConfig.apiBaseUrl; //"http://localhost:61872/api/";
    global.dateFormat = "dd/MM/yyyy";
    console.log("apiBaseURL: " + global.apiBaseURl);
    console.log("Date Formate: " + global.dateFormat);
  };


  public setLoading(isloading: boolean) {
    this.loading = isloading;
  }
}
