import { Component, OnInit } from '@angular/core';
import { Menu, MenuChilds, ProfileMenu } from './menu.model';
import { MenuService } from './shared/menu.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Customer, ProfilePhotosDetails } from '../../../my-profile/Customer';
import { ToastrService } from 'ngx-toastr';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { User } from '../../../login/user';
import { ModalWindowService } from '../../../shared/modal';
import { FormlabelsService } from '../../../shared/formlabels.service';
import { param } from 'jquery';
import { ConfirmationDialogService } from '../../../shared/delete-confirmation/confirmation-dialog.service';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  public HeaderMenu: Menu[];
  public PageProfileMenu: ProfileMenu[];
  public dtOptions: DataTables.Settings = {};
  public customerLogo: any;
  public mystring: any;
  public customer: any;
  message: any;
  imagePath: any;
  imgURL: any;
  public profilePhotosDetails: any
  public customerName: any;
  //public userDetails = JSON.parse(localStorage.getItem('userData'));
  public userDetails: any;

  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading: boolean = false;
  public selectSubMenu: string;
  public selectdMenuName: string;
  public Internaluser: any;
  public roleDetails: any = [];
  public statusDetails: any;
  public OldPassword: string;
  public NewPassword: string;
  public ConfirmPassword: string;
  public isDisabled: boolean = true;
  public isPasswordMatch: boolean = false;
  public isPatternMatch: boolean = false;
  public PasswordRequired: boolean = false;
  public isOldPasswordWrong: boolean = false;
  public PasswordUpdateSuccess: boolean = false;
  public PasswordUpdatefailed: boolean = false;
  public isVerified: boolean = false;
  public Hidemodal: string;
  public pageLabels: any = [];
  public NotificationCount: any;
  public NotificationData: any = [];
  public closeResult: string;
  public NotificationsDistinct: any = [];
  public singleNotification: any = [];
  public NotificationTable: any = [];
  public NotificationFileName: string;
  public userType: string;
  public showNotification: boolean = false;

  constructor(private _route: Router, private _menuService: MenuService, private toastr: ToastrService, private activatedRoute: ActivatedRoute, private modalService: ModalWindowService,
    private confirmationDialogService: ConfirmationDialogService,
    private formLabelService: FormlabelsService, private modalServicepo: NgbModal) { }

  ngOnInit() {
    //default Image Url
    this.imgURL = "../../../../assets/customer/images/profile-icon-large.jpg";
    let userData = JSON.parse(localStorage.getItem('userData'));
    this.userType = userData.userEntityIdentifier == '2' ? "customer" : "other";
    if (userData == null) {
      this._route.navigate(["/"]);//If userData ia null navegate te user to login page.
      return;
    }


    this.customer = new Customer();
    this.profilePhotosDetails = new ProfilePhotosDetails();

    this.HeaderMenu = [];
    this.PageProfileMenu = [];

    this._menuService.serviceGetMenusByUserRole(userData.roleMasterId).then((result) => {
      let activeModule: string = '';
      let activeSubModule: string = '';
      try {
        if (this.activatedRoute.snapshot.url.length > 0) {
          activeModule = this.activatedRoute.snapshot.url[0].path;
        }

        if (window.location.pathname == "/CommercialDocuments") {
          activeModule = "CommercialDocuments"
        }

        if (window.location.pathname == "/dashboard") {
          activeModule = "Dashboard"
        }

        if (activeModule == "ShipmentTracking") {
          activeModule = "Shipment Tracking";
        }

        activeSubModule = window.location.pathname.split('/')[2];
      }
      catch (ex) {

      }


      try {
        //console.log(result);
        if (result.isSuccessful == true) {
          result.data.headerMenu.forEach(element => {
            let menuLink: Menu;
            menuLink = new Menu();
            if (element.menuChilds.length > 0) {
              menuLink.MenuChilds = [];
              element.menuChilds.forEach(childElement => {
                let subMenuLink: MenuChilds;
                subMenuLink = new MenuChilds();
                subMenuLink.MenuControllName = childElement.menuControllerName;
                subMenuLink.MenuLinkName = childElement.menuLinkText;
                subMenuLink.MenuName = childElement.menuPageName;
                if (childElement.menuPageName == activeSubModule) {
                  this.selectSubMenu = childElement.menuLinkText;
                }
                menuLink.MenuChilds.push(subMenuLink);
              });
            } else {
              menuLink.MenuChilds = null;
            }
            menuLink.MenuControllName = element.menuControllerName;
            menuLink.MenuLinkName = element.menuLinkText;
            menuLink.MenuName = element.menuPageName;
            //menuLink.MenuAcctive = element.menuControllerName == activeModule ? true:false;
            menuLink.MenuAcctive = element.menuControllerName == activeModule ? true : element.menuLinkText == activeModule ? true : false;

            this.HeaderMenu.push(menuLink);

          });
          //Profile menu
          result.data.profileMenu.forEach(element => {
            this.PageProfileMenu.push({
              MenuLinkController_name: element.menuLinkControllerName,
              MenuLinkPageName: element.menuLinkPageName,
              MenuLinkText: element.menuLinkText
            });
          });
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        } else if (result.isSuccessful == false) {
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
        } else {
          this.toastr.clear();
          this.toastr.error("Failed to connect to the server", "Error", { timeOut: 5000 });
        }
      } catch (Error) {

      }
    });

    this.Internaluser = {};
    this.getCustomerCompanyLogo();
    this.getUserProfileImage();
    this.getInternalUserDetailsForEdit(userData.userId)
    this.getRoleMaster();
    this.getStatusDetail();
    this.getAllLabelsAndMessages();
    if (this.userType == "customer")//only give api call for po notiffication if it is customer
    {
      this.showNotification = true;
      this.getNotificationCount();
      this.NotificationLoader();
    }
  }


  ////Added by Varsha on 05-09-2019
  ////To Get Customer Logo Image
  async getCustomerCompanyLogo() {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    if (this.userDetails != null) {
      this.userDetails.userId;
      this._menuService.getCustomerCompanyLogo(this.userDetails.userId)
        .then((result) => {
          try {
            if (result.isSuccessful == true) {
              this.customerLogo = result.data;
            }
            else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
              this.toastr.clear();
              this.toastr.error(result.message, "Error", { timeOut: 5000 });
              localStorage.removeItem('userData');
              this._route.navigate(['/']);
            } else if (result.isSuccessful == false) {
              this.toastr.clear();
              this.toastr.info(result.message, "Info", { timeOut: 5000 });
            } else {
              this.toastr.clear();
              this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
            }
          } catch (Error) {

          }
        })
    }
  }

  ////Added by Varsha on 05-09-2019
  ////To Get User Profile Image
  async getUserProfileImage() {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.customer = this.userDetails.userFirstName;
    this._menuService.getUserProfileImage(this.userDetails.userId)
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.imgURL = result.data;
          }
          else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
            this.toastr.clear();
            this.toastr.error(result.message, "Error", { timeOut: 5000 });
            localStorage.removeItem('userData');
            this._route.navigate(['/']);
          } else if (result.isSuccessful == false) {
            this.toastr.clear();
            // this.toastr.info(result.message, "Info", { timeOut: 5000 });
          } else {
            this.toastr.clear();
            this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
          }
        } catch (Error) {

        }
      })
  }

  ////Added by Varsha on 05-09-2019
  ////To Get Profile Image And Convert Into Base64
  detectProfileImageFiles(event) {

    let files = event.target.files;
    let fileExtention = event.target.files[0].name.split('.')[1];
    let mimetype = files[0].type;
    if (files) {

      if (mimetype.match(/image\/*/) == null) {
        this.toastr.error("Please select valid image file.", "", { timeOut: 5000 });
        this.profilePhotosDetails.profileBase64Image = null;
        this.profilePhotosDetails.profileImageName = null;
        return;
      }
      let file = files[0];
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.mystring = e.target.result;
        this.imgURL = this.mystring;
        if (mimetype == "image/jpeg") {
          let picstring = this.mystring.replace('data:image/jpeg;base64,', ' ');
          this.profilePhotosDetails.profileBase64Image = picstring;
          this.profilePhotosDetails.profileImageName = files[0].name;
        }
        else if (mimetype == "image/jpg") {
          let picstring = this.mystring.replace('data:image/jpg;base64,', ' ');
          this.profilePhotosDetails.profileBase64Image = picstring;
          this.profilePhotosDetails.profileImageName = files[0].name;
        }
        else if (mimetype == "image/png") {
          let picstring = this.mystring.replace('data:image/png;base64,', ' ');
          this.profilePhotosDetails.profileBase64Image = picstring;
          this.profilePhotosDetails.profileImageName = files[0].name;
        }
        else if (mimetype == "image/gif") {
          let picstring = this.mystring.replace('data:image/gif;base64,', ' ');
          this.profilePhotosDetails.profileBase64Image = picstring;
          this.profilePhotosDetails.profileImageName = files[0].name;
        }
        this.insertUserProfileImage();
      }
      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file);
      }
    }

    //Priview Image code.
    // if (event.target.files && event.target.files[0]) {
    //   var reader = new FileReader();
    //   reader.readAsDataURL(event.target.files[0]); // read file as data url
    //   reader.onload = (event) => { // called once readAsDataURL is completed
    //     this.imgURL = reader.result;        
    //   }
    // }

  }

  //Added by Varsha on 06-09-2019
  //To Insert User Profile Path into UserDeatils Table.
  public insertUserProfileImage() {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.loading = true;
    if (this.userDetails == null) {
      return;
    }
    this.profilePhotosDetails.userId = this.userDetails.userId;
    if (this.userDetails.userId == undefined) {

    }
    else {
      if (this.profilePhotosDetails.profileBase64Image != null) {

        this._menuService.userProfileImageUpload(this.profilePhotosDetails)
          .then((result) => {
            if (result.isSuccessful == true) {
              this.imgURL = result.data;
              this.toastr.clear();
              this.toastr.success(result.message, "Success", { timeOut: 5000 });
            }
            else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
              this.toastr.clear();
              this.toastr.error(result.message, "Error", { timeOut: 5000 });
              this.loading = false;
              localStorage.removeItem('userData');
              this._route.navigate(['/']);
            } else if (result.isSuccessful == false) {
              this.toastr.clear();
              this.toastr.error(result.message, "Error", { timeOut: 5000 });
            } else {
              console.log(result);
              this.loading = false;
              this.toastr.clear();
              this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
            }
          })
      } else {
        this.toastr.clear();
        this.toastr.error(this.pageLabels[95], "Error", { timeOut: 5000 });//Please select valid image file.[95]
      }
    }
  }

  //Added by Mahesh on 26-09-2019
  // To logout the user
  logout() {
    localStorage.removeItem('userData');//Destroy the session storage. 
    this._route.navigate(['/login']); //and navigate the user to login page
  }

  //Added by Shailesh 12-11-2019
  setTheMenuActive(menu: Menu) {
    // if (menu.MenuLinkName == "Dashboard" || menu.MenuControllName == "CommercialDocuments") {

    //   for (var i = 0; i < this.HeaderMenu.length; i++) {
    //     if (this.HeaderMenu[i].MenuAcctive == true) {
    //       this.HeaderMenu[i].MenuAcctive = false;
    //       break;
    //     }
    //   }
    // }

    for (var i = 0; i < this.HeaderMenu.length; i++) {
      if (this.HeaderMenu[i].MenuAcctive == true) {
        this.HeaderMenu[i].MenuAcctive = false;
        break;
      }
    }


    menu.MenuAcctive = true;
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    if ((this.userDetails.userEntityIdentifier == 2 || this.userDetails.userEntityIdentifier == 1) && menu.MenuName == "Dashboard") {
      this._route.navigate(['/dashboard']);
    }

  }

  //Added by Mahesh on 20-05-2020
  setTheSubMenuActive(subMenu: MenuChilds, menu: Menu) {
    //subMenu.SubMenuAcctive = true;
    menu.MenuAcctive = true;
    this.selectSubMenu = subMenu.MenuLinkName;
  }


  onProfileMenuSelect() {
    for (var i = 0; i < this.HeaderMenu.length; i++) {
      if (this.HeaderMenu[i].MenuAcctive == true) {
        this.HeaderMenu[i].MenuAcctive = false;
        break;
      }
    }
  }
  //added by Omkar 23rd July 2020
  async getInternalUserDetailsForEdit(userId) {
    this._menuService.getInternalUserDetailsForEdit(userId)
      .then((result) => {
        if (result.isSuccessful == true) {
          this.Internaluser = result.data;
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        } else if (result.isSuccessful == false) {
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
        } else {
          this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
          this.loading = false;
        }
      }).catch((result) => {
        this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]

      })
  }

  async getRoleMaster() {

    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let userId: number = this.userDetails.userId;
    this._menuService.getUserRole(userId)
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.roleDetails = result.data;
          }
          else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
            this.toastr.error(result.message, "", { timeOut: 5000 });
            localStorage.removeItem('userData');
            this._route.navigate(['/']);
          } else if (result.isSuccessful == false) {
            this.toastr.error(result.message, "", { timeOut: 5000 });
          } else {
            this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          }
        } catch (Error) {

        }
      })
  }

  async getStatusDetail() {
    this._menuService.getUserStatus()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.statusDetails = result.data;
          }
          else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
            this.toastr.error(result.message, "", { timeOut: 5000 });
            localStorage.removeItem('userData');
            this._route.navigate(['/']);
          } else if (result.isSuccessful == false) {
            this.toastr.error(result.message, "", { timeOut: 5000 });
          } else {
            this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          }
        } catch (Error) {

        }
      })
  }

  public ToggeldivResetPassword() {
    $(".divResetPassword").toggle();
    $(".divProfileImage").toggle();
  }

  public VerifyPassword() {
    this.isOldPasswordWrong = false;
    this.isVerified = false;
    if (this.OldPassword != '' && this.OldPassword != undefined) {
      this.userDetails = JSON.parse(localStorage.getItem('userData'));
      var TUser = new User;
      TUser.userEmailId = this.userDetails.userEmail
      TUser.userPassword = this.OldPassword;
      this._menuService.login(TUser)
        .then((result) => {
          if (result.isSuccessful == true) {
            //enable button and textboxes
            this.toastr.success(this.pageLabels[498], "", { timeOut: 5000 });//password verified[498]
            //this.isVerified = result.isSuccessful;
            this.isDisabled = !result.isSuccessful;
          }
          else {
            //disable buttons and textboxes
            this.isDisabled = !result.isSuccessful;
            this.isOldPasswordWrong = !result.isSuccessful;
          }
        }).catch((result) => {
          this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          this.loading = false;
          console.log(result);
        })

    } else {
      //flag to show error message- Old password required
      this.PasswordRequired = true;
    }

  }

  public ResetPassword() {
    if (this.NewPassword == this.ConfirmPassword) {
      this.userDetails = JSON.parse(localStorage.getItem('userData'));
      var resetPasswordModel = {
        'userid': this.userDetails.userId,
        'Password': this.NewPassword
      }
      this._menuService.ResetPassword(resetPasswordModel)
        .then((result) => {
          if (result.isSuccessful == true) {
            //enable button and textboxes
            this.PasswordUpdateSuccess = result.isSuccessful;
            this.toastr.success('The password has been updated successfully. Redirecting to login.').onHidden.subscribe((data) => {
              window.location.reload();
            });

          }
          else {
            //disable buttons and textboxes
            this.PasswordUpdatefailed = result.isSuccessful;
          }
        }).catch((result) => {
          this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          this.loading = false;
          console.log(result);
        })
    }
    else {
      //flag to show error message-  password not match
      this.isPasswordMatch = true;
    }

  }
  public ValidatePassword() {
    var pattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_#])[A-Za-z\d@$!%*?&_#]{8,}/;
    let regxpatter = new RegExp(pattern);
    if (!regxpatter.test(this.NewPassword)) {
      //flag to show error message if pattern not matches
      this.isPatternMatch = true;
    } else {
      this.isPatternMatch = false;
    }
  }


  //added by Omkar 23rd Aug 2020
  statusForOldPasswordEye: boolean = false;
  inputTypeForOldPassword: string = 'password';

  statusForNewPasswordEye: boolean = false;
  inputTypeForNewPassword: string = 'password';

  statusForConfirmPasswordEye: boolean = false;
  inputTypeForConfirmPassword: string = 'password';
  clickEventForShowHidePassword(inttype: any) {
    if (inttype == 1) {
      //for Old Password
      this.statusForOldPasswordEye = !this.statusForOldPasswordEye;
      if (this.statusForOldPasswordEye == false) {
        this.inputTypeForOldPassword = 'password';
      } else {
        this.inputTypeForOldPassword = 'text';
      }
    } else if (inttype == 2) {
      //for new Password
      this.statusForNewPasswordEye = !this.statusForNewPasswordEye;
      if (this.statusForNewPasswordEye == false) {
        this.inputTypeForNewPassword = 'password';
      } else {
        this.inputTypeForNewPassword = 'text';
      }
    } else if (inttype == 3) {
      //for confirm Password
      this.statusForConfirmPasswordEye = !this.statusForConfirmPasswordEye;
      if (this.statusForConfirmPasswordEye == false) {
        this.inputTypeForConfirmPassword = 'password';
      } else {
        this.inputTypeForConfirmPassword = 'text';
      }
    }

  }

  //added by kunal to get labels and messages from resources on 23/8/2020
  async getAllLabelsAndMessages() {
    this.formLabelService.getAllLabelsAndMessages()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.pageLabels = [];
            for (let i = 0; i <= result.data.length - 1; i++) {
              this.pageLabels.push(result.data[i].resourceValue);
            }
          }
          else if (result.isSuccessful == false) {
            this.toastr.error(result.message, "", { timeOut: 5000 });
          }
          else {
            this.toastr.error("Failed to connect to the server", "", { timeOut: 5000 });
          }
        } catch (Error) {
        }
      })
      .catch((err) => {
      })
  }

  public matchPassword() {
    if (this.NewPassword == this.ConfirmPassword) {
      this.isPasswordMatch = false;
    }
    else {
      this.isPasswordMatch = true;
    }
  }

  //kunal for notification count
  public getNotificationCount() {
    this.NotificationCount = 0;
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let userId: number = this.userDetails.userId;
    this._menuService.getPoNotification(userId)
      .then((result) => {
        if (result.isSuccessful == true) {
          this.NotificationData = [];
          this.NotificationData = result.data;
          //this.NotificationCount = result.data.length;//add notification count from API 

          //select the distinct based on File Name
          this.NotificationsDistinct = result.data.filter((thing, i, arr) => {
            return arr.indexOf(arr.find(t => t.notificationfileName === thing.notificationfileName)) === i;
          });
          this.NotificationCount = this.NotificationsDistinct.length;
          //console.log(distinctThings);
        }
        else {
          this.NotificationData = [];
          this.NotificationCount = 0;
        }
      }).catch((result) => {
        this.NotificationData = [];
        this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
        this.loading = false;
        this.NotificationCount = 0;
        console.log(result);
      })
  }

  public NotificationLoader() {
    /* Js For Navigation Start */
    $(".toggle").click(function () {
      console.log("toggling sidebar");
      $(".sidebar").toggleClass('active-sidebar');

    });
    $(".cancel").click(function () {
      console.log("toggling visibility");
      $(this).parent().toggleClass('gone');

    });
    /* Js For Navigation End */
  }

  //po pop-up
  public ReadPo(content, item) {
    // notificationId:Number, notificationDescription:string, notificationPoNumber:string
    // let userId: number = this.userDetails.userId;
    // this.confirmationDialogService.confirm("Po Description",notificationDescription,"Mark as Read")
    //   .then(
    //     (confirmed) => {
    //       if (confirmed) {
    //         this._menuService.updateNotification(notificationId)
    //         .then((result)=> {
    //           if(result.isSuccessful == true){
    //             //this.GetPos();
    //             this._menuService.getPoNotification(userId)
    //             .then((result) => {
    //               if (result.isSuccessful == true) {
    //                 this.notificationData = [];
    //                 this.notificationData = result.data;
    //               }
    //               else {
    //                 this.notificationData = [];
    //               }
    //             }).catch((result) => {
    //               this.notificationData = [];
    //               console.log(result);
    //             })
    //             console.log("Update successfull");
    //             this.toastr.success("Updated Notification Status");
    //             window.location.reload();//to get the
    //           }
    //           else{
    //             console.log("Update failed");
    //             this.toastr.error("Failed to update Notification Status");
    //           }
    //         })
    //         console.log("confirm/yes");
    //       }
    //       else{
    //         console.log("rejected/cancel");
    //       }
    //     })
    //   .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

    //this.modalService.open("po_notification");
    this.singleNotification = [];
    this.NotificationTable = [];
    this.NotificationFileName = "";
    this.NotificationFileName = item.notificationfileName;
    console.log(item);
    this.singleNotification = item;
    for (let index = 0; index < this.NotificationData.length; index++) {
      if (this.NotificationData[index].notificationfileName == item.notificationfileName) {
        this.NotificationTable.push(this.NotificationData[index]);
      }
    }

    this.modalServicepo.open(content, { size: 'lg', backdrop: 'static', windowClass: '' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  closePoModal() {
    this.modalServicepo.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  //mark all file notifications as read
  ReadAll() {
    this._menuService.updateNotification(this.NotificationTable[0].notificationfileName)
      .then((result) => {
        if (result.isSuccessful == true) {
          window.location.reload();
        }
      });
  }
}
