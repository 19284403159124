import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DashBoardSrvService } from './dash-board-srv.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDashboardTabularComponent } from './modal-dashboard-tabular/modal-dashboard-tabular.component';
import { ModalDashboardPVComponent } from './modal-dashboard-pv/modal-dashboard-pv.component';
import { ToastrService } from 'ngx-toastr';
import { NgxChartsModule, ColorHelper } from '@swimlane/ngx-charts';
import { ShipmentData } from './shipment-data';
import { FormlabelsService } from '../../shared/formlabels.service';
import { data } from 'jquery';
import { promise } from 'protractor';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public userEntityIdentifier: number;
  public verifyMessageforOtherDetails: string;
  pendingack: any[];
  //variables for graphs
  single: any[];
  multi: any[];
  public openPoGdtitle: any;
  public pvGdTitle: string;
  priceVarianceGd: any[];
  userDetails: any;

  viewForPV: any[] = [600, 500];
  viewForOpenPO: any[] = [300, 300];
  view: any[] = [1000, 400];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Suppliers';
  showYAxisLabel = true;
  yAxisLabel = 'No. Of Incomplete Shipments';
  legendPosition = 'below';
  showDataLabel = false;
  chartNames: string[];
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  isDateForOpenPo: boolean = false;
  public colors: ColorHelper;
  //data for tabular
  poOpenData: Array<any> = [];
  pvData: Array<any> = [];

  dataForFirstBlock: Array<ShipmentData> = [];


  dataForDelayShipments: Array<ShipmentData> = [];
  countOfTotalDelayShipments: number = 0;

  dataForPendingShipments: Array<ShipmentData> = [];
  countOfTotalPendingShipments: number = 0;

  supplierWiseIncompleteShipment: any[];
  destinationWiseShipmentCount: any[];
  loadingPortwiseCount: any[];
  delayedShipmentCount: any[];


  dataForDelayShipmentsPO: Array<ShipmentData> = [];
  countOfTotalDelayShipmentsPO: number = 0;

  public pageLabels: any = [];

  dataForMatrixByShipmentType: Array<ShipmentData> = [];
  countOfTotalMatrixByShipmentType: number = 0;


  dataForShipmentsCurrentlyAtRisk: Array<ShipmentData> = [];
  countForShipmentsCurrentlyAtRisk: number = 0;

  constructor(private _route: Router, private dashBoardDataService: DashBoardSrvService, private modalService: NgbModal,
    private toastrService: ToastrService, private elem: ElementRef, private renderer: Renderer2,
    private formLabelService: FormlabelsService) {
    // this.GetUserEntityId(); 
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    if (this.userDetails == null) {
      //this._route.navigate(['/dashboard']);
      this._route.navigate(['/login']);
    } else if (this.userDetails != null && this.userDetails.userStatusCfgId == 1) {
      this._route.navigate(['/login']);
    }
    this.single = [];
    this.supplierWiseIncompleteShipment = [];
    this.getDataForCustomerDashBoard().then((data) => {
      if (data == 1) {
      }
    });
    this.getAllLabelsAndMessages();
    this.getverifiedotherDetails();
  }

  ngAfterViewInit() {
    const dom: HTMLElement = this.elem.nativeElement;
    const elements = document.querySelectorAll('.horizontal-legend');
    elements.forEach(element => {
      this.renderer.removeClass(element, 'horizontal-legend');
      this.renderer.addClass(element, 'vertical-legend')
    });
  }

  GetUserEntityId() {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    if (this.userDetails == null) {
      this._route.navigate(['/login']);
      return;
    }

    this.dashBoardDataService.getUserEntityId()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.userEntityIdentifier = result.data;
            console.log(this.userEntityIdentifier);
            if (this.userEntityIdentifier == 3) {
              this._route.navigate(['/Home/Dashboard']);
            }
            else if (this.userEntityIdentifier == 2 || this.userEntityIdentifier == 1) {
              this._route.navigate(['/dashboard']);
            }
          }
          else {
            this.toastrService.error(this.pageLabels[561], "", { timeOut: 5000 })//Fail to Load[561]
          }
        }
        catch (Error) {
          //alert(Error.message);
        }
      });
  }

  onSelect(event: any) {
    console.log(event);
    let modalComponent = this.modalService.open(ModalDashboardTabularComponent, { size: 'lg' });
    modalComponent.componentInstance.tableData = this.poOpenData.filter(x => x.supplierName == event.name);
    modalComponent.componentInstance.gdTitle = "Open PO's";
    modalComponent.componentInstance.headerColumns = ["Destination", "PO Ack Status", "PO Creation Date", "PO NO.", "Req. Arrival Date", "ShipByDate", "Total Value"];
    console.log(modalComponent.componentInstance.headerColumns);
    console.log("tableData", modalComponent.componentInstance.tableData);
  }


  getDataForCustomerDashBoard(): Promise<any> {
    return new Promise((response) => {
      this.dashBoardDataService.serviceGetTheCustomerDashBoardData().subscribe((data) => {
        if (data.isSuccessful) {
          this.openPoGdtitle = data.data.gdTitle;
          console.log("title", data.data.gdTitle);
          this.single = [];
          data.data.statusWisePOGD.forEach(element => {
            this.single.push({ 'name': element.sName, 'value': element.countOfPo });
          });

          if (this.single.length > 0) {
            this.isDateForOpenPo = true;
          }
          this.poOpenData = data.data.statusWisePO;
          console.log("POOpenData : ", this.poOpenData);

          this.pvGdTitle = data.data.gdTitle3;
          this.priceVarianceGd = [];
          data.data.statusWisePVGD.forEach(element => {
            this.priceVarianceGd.push({ 'name': element.sName, 'value': element.countOfPV });
          });
          //this.single =  this.priceVarianceGd;       
          this.chartNames = this.priceVarianceGd.map((d: any) => d.name);
          this.colors = new ColorHelper(this.colorScheme, 'ordinal', this.chartNames, this.colorScheme);
          console.log(this.chartNames);

          if (data.data.shipmentTypeCount != null) {
            data.data.shipmentTypeCount.forEach(element => {
              this.dataForFirstBlock.push({
                shipemntType: element.typeOfShipment,
                shipmentCount: element.count
              });
            });
          }

          if (data.data.delayRiskCount != null) {
            data.data.delayRiskCount.forEach(element => {
              this.dataForDelayShipments.push({
                shipemntType: element.typeOfShipment,
                shipmentCount: element.count
              });
              this.countOfTotalDelayShipments = this.countOfTotalDelayShipments + element.count;
            });
          }

          if (data.data.shipmentsPendingUpdatesCount != null) {
            data.data.shipmentsPendingUpdatesCount.forEach(element => {
              this.dataForPendingShipments.push({
                shipemntType: element.typeOfShipment,
                shipmentCount: element.count
              });
              this.countOfTotalPendingShipments = this.countOfTotalPendingShipments + element.count;
            });
          }

          //this.supplierWiseIncompleteShipment = data.data.supplierIncompleteShipments.myArray;
          this.supplierWiseIncompleteShipment = [];
          if (data.data.supplierIncompleteShipments != null) {
            data.data.supplierIncompleteShipments.forEach(element => {
              this.supplierWiseIncompleteShipment.push({ 'name': element.sName, 'value': element.count });
            });
          }

          //added by Kunal for pending acknowledgement bar chart on 31/8/2020
          this.pendingack = [];
          if (data.data.pendingAckitemLists != null) {
            data.data.pendingAckitemLists.forEach(element => {
              this.pendingack.push({ 'name': element.sName, 'value': element.countOfPo });
            });
          }

          //set the data for loadingPortwiseCount and destinationWiseShipmentCount
          this.destinationWiseShipmentCount = [];
          if (data.data.destinationWiseShipmentCount != null) {
            data.data.destinationWiseShipmentCount.forEach(element => {
              this.destinationWiseShipmentCount.push({
                "name": element.sType,
                "value": element.count
              });
            });
          }

          this.loadingPortwiseCount = [];
          if (data.data.loadingPortwiseCount != null) {
            data.data.loadingPortwiseCount.forEach(element => {
              this.loadingPortwiseCount.push({
                "name": element.sType,
                "value": element.count
              });
            });
          }

          this.dataForDelayShipmentsPO = [];
          if (data.data.shipmentDealyedCount != null) {
            data.data.shipmentDealyedCount.forEach(element => {
              this.dataForDelayShipmentsPO.push({
                shipemntType: element.typeOfShipment,
                shipmentCount: element.count
              });
              this.countOfTotalDelayShipmentsPO = this.countOfTotalDelayShipmentsPO + element.count;
            });
          }

          if (data.data.matrixByShipmentType != null) {
            data.data.matrixByShipmentType.forEach(element => {
              this.dataForMatrixByShipmentType.push({
                shipemntType: element.typeOfShipment,
                shipmentCount: element.count
              });
              this.countOfTotalMatrixByShipmentType = this.countOfTotalMatrixByShipmentType + element.count;
            });
          }

          //below commented by kunal on 12-01-2022
          // if (data.data.matrixShipmentsCurrentlyAtRisk != null) {
          //   data.data.matrixShipmentsCurrentlyAtRisk.forEach(element => {
          //     this.dataForShipmentsCurrentlyAtRisk.push({
          //       shipemntType: element.typeOfShipment,
          //       shipmentCount: element.count
          //     });
          //     this.countForShipmentsCurrentlyAtRisk = this.countForShipmentsCurrentlyAtRisk + element.count;
          //   });            
          // }

          if (data.data.matrixShipmentsCurrentlyAtRisk != null) {
            this.countForShipmentsCurrentlyAtRisk = data.data.matrixShipmentsCurrentlyAtRisk;
          }
          console.log(this.single);
          response(1);
        }
      },
        error => {
          console.log(error);
        });
    });
  }

  //Added by Dnyaneshwari
  onPVSelect(event: any) {
    console.log(event);
    let modalComponent3 = this.modalService.open(ModalDashboardPVComponent, { size: 'lg' });
    modalComponent3.componentInstance.barData = this.pvData.filter(x => x.supplierName == event.name);

    modalComponent3.componentInstance.headerColumnsPV = ["PO Number", "SKU Number", "SKU Description", "Ordered Quantity", "Customer Price", "Suplier Price", "Price Variance"];
    console.log(modalComponent3.componentInstance.headerColumnsPV);
    console.log("tableData", modalComponent3.componentInstance.barData);
  }

  //added by Kunal on 13/8/2020 get labels from db mlocalstring
  async getAllLabelsAndMessages() {
    this.formLabelService.getAllLabelsAndMessages()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.pageLabels = [];
            for (let i = 0; i <= result.data.length - 1; i++) {
              this.pageLabels.push(result.data[i].resourceValue);
            }
          }
          else if (result.isSuccessful == false) {
            this.toastrService.error(result.message, "", { timeOut: 5000 });
          }
          else {
            this.toastrService.error("Failed to connect to the server", "", { timeOut: 5000 });
          }
        } catch (Error) {

        }
      })
      .catch((err) => {

      })
  }

  //added by Omkar on 11th OCt 2020 
  //if data is missing then redirect to InternalUser page for customer.
  public getverifiedotherDetails() {
    this.dashBoardDataService.VerifyOtherDetails()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.verifyMessageforOtherDetails = result.data;
            console.log(this.verifyMessageforOtherDetails);
            if (this.verifyMessageforOtherDetails != "All Info") {

              this.toastrService.error(this.verifyMessageforOtherDetails, "", { timeOut: 5000 })
              //redirect to internaluser page
              setTimeout(() => {
                this._route.navigate(['/internaluser'], { queryParams: { showPODetails: 1 } });
              }, 4000);

            }
          }
          else {
            this.toastrService.error(this.pageLabels[561], "", { timeOut: 5000 })//Fail to Load[561]
          }
        }
        catch (Error) {
          //alert(Error.message);
        }
      });
  }

  //added by kunal to format x axis values(rounded-off)
  axisFormat(val) {
    if (val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }
}
