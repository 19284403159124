export class reportSearchParameters {
  supplierID: number;
  supplierName: string;
  billOfLading: string;
  containerNumber: string;
  poNumber: string;
  skuNumber: string;
  portOfOrigin: string;
  supplierShipmentFromDate: Date;
  supplierShipmentToDate: Date;
  destinationID: number;
  etdFromDate: Date;
  etdToDate: Date;
  etaFromDate: Date;
  etaToDate: Date;
  urlPathString: string;
  isShowAllSelected: boolean;
  shipmentType: string;
  dischargeID: String;
  userId: number;
  poCreationFromDate: Date;
  poCreationToDate: Date;
  shipmentStatus: number;
  carrierId: any;
  portOfOriginToDate: any;
  portOfOriginFromDate: any;
  portOfDischargeFromDate: any;
  portOfDischargeToDate: any;
  poShipmentFromDate: Date;
  poShipmentToDate: Date;
}
