import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';//added by vivek Redekar

import { AppComponent } from './app.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { FooterComponent } from './layout/site-layout/footer/footer.component';
import { MenuComponent } from './layout/site-layout/menu/menu.component';
import { SiteLayoutComponent } from './layout/site-layout/site-layout/site-layout.component';
import { HeaderComponent } from './layout/site-layout/header/header.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './customer/dashboard/dashboard.component';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { Global } from './shared/global';
import { MyProfileComponent } from './my-profile/my-profile.component';

import { NgxLoadingModule } from 'ngx-loading';
import { InternaluserComponent } from './customer/internaluser/internaluser.component';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule } from './shared/modal';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { CustomerDirective } from '../app/my-profile/customer.directive';
import { DeleteConfirmationComponent } from './shared/delete-confirmation/delete-confirmation.component';
import { SupplierComponent } from './customer/supplier/supplier.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SupplierInternalUserComponent } from './customer/supplier/supplier-internal-user/supplier-internal-user.component';

//Added for ngx-grpahs
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalDashboardTabularComponent } from './customer/dashboard/modal-dashboard-tabular/modal-dashboard-tabular.component';
import { ModalDashboardPVComponent } from './customer/dashboard/modal-dashboard-pv/modal-dashboard-pv.component';
import { CommercialDocumentsComponent } from './commercial-documents/commercial-documents.component';
import { AuthGuard } from './_helpers/auth.guard';
import { CommonActionsComponent } from './poupload/common-actions/common-actions.component';
import { AppConfigService } from './app-config.service';
import { PoNotificationComponent } from './customer/po-notification/po-notification/po-notification.component';
import { AgentComponent } from './customer/agent/create-agent/agent.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginLayoutComponent,
    FooterComponent,
    MenuComponent,
    SiteLayoutComponent,
    HeaderComponent,
    LoginComponent,
    DashboardComponent,
    MyProfileComponent,
    InternaluserComponent,
    SupplierComponent,
    CustomerDirective,
    DeleteConfirmationComponent,
    SupplierInternalUserComponent,
    ModalDashboardTabularComponent,
    ModalDashboardPVComponent,
    CommercialDocumentsComponent,
    PoNotificationComponent,
    AgentComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    ToastNoAnimationModule.forRoot(),
    NgbModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      {
        path: '',
        component: LoginLayoutComponent,
        children: [
          { path: '', component: LoginComponent, pathMatch: 'full' },
          { path: 'login', component: LoginComponent, pathMatch: 'full' }
        ]
      },
      {
        path: 'PoUpload',
        component: SiteLayoutComponent,
        //canLoad: [AuthGuard],
        loadChildren: () => import('./poupload/poupload.module').then(mod => mod.PouploadModule)
      },
      {
        path: 'supplier',
        component: SiteLayoutComponent,
        loadChildren: () => import('./supplier/supplier.module').then(mod => mod.SupplierModule)
      }
      ,
      {
        path: 'Home',
        component: SiteLayoutComponent,
        loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule)
      }
      ,
      {
        path: 'Masters',
        component: SiteLayoutComponent,
        loadChildren: () => import('./master/master.module').then(mod => mod.MasterModule)
      },
      {
        path: 'shipment_creation',
        component: SiteLayoutComponent,
        loadChildren: () => import('./shipment-creation/shipment-creation.module').then(mod => mod.ShipmentCreationModule)
      },
      {
        path: 'ShipmentTracking',
        component: SiteLayoutComponent,
        canLoad: [AuthGuard],
        loadChildren: () => import('./shipment-tracking/shipment-tracking.module').then(mod => mod.ShipmentTrackingModule)
      },
      {
        path: 'Reports',
        component: SiteLayoutComponent,
        loadChildren: () => import('./Reports/reports.module').then(mod => mod.ReportsModule)
      },
      {
        path: 'instructionAndDocuments',
        component: SiteLayoutComponent,
        loadChildren: () => import('./instruction-and-documents/instruction-and-documents.module').then(mod => mod.InstructionAndDocumentsModule)
      },
      {
        path: '',
        component: SiteLayoutComponent,
        children: [
          { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
          { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthGuard] },
          { path: 'internaluser', component: InternaluserComponent, canActivate: [AuthGuard] },
          { path: 'manageSupplier', component: SupplierComponent, canActivate: [AuthGuard] },
          { path: 'manageAgent', component: AgentComponent, canActivate: [AuthGuard] },
          { path: 'supplier-internal-user', component: SupplierInternalUserComponent, canActivate: [AuthGuard] },
          { path: 'CommercialDocuments_new/CommercialDocuments', component: CommercialDocumentsComponent, canActivate: [AuthGuard] },
          //{ path: 'poupload/PoCommonAction', component: CommonActionsComponent, canActivate: [AuthGuard] }
          //{path: 'PoNotification',canActivate: [AuthGuard],component: PoNotificationComponent},
        ]
      },
      {
        path: 'Bookings',
        component: SiteLayoutComponent,
        loadChildren: () => import('./bookings/bookings.module').then(mod => mod.BookingsModule)
      },
      { path: '**', redirectTo: 'login', pathMatch: 'full' }

    ]),
    NgxLoadingModule.forRoot({}),
    DataTablesModule,
    ShowHidePasswordModule,
    ModalModule

  ],
  providers: [
    Global,
    AuthGuard,
    AppConfigService,
    { provide: APP_INITIALIZER, useFactory: (config: AppConfigService) => () => config.loadAppConfig(), deps: [AppConfigService], multi: true }
  ],
  entryComponents: [
    DeleteConfirmationComponent, ModalDashboardTabularComponent, ModalDashboardPVComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
