export class Supplier {
  CustomerId: number;
  SupplierBuyerEmailid: string;
  SupplierBuyerName: string;
  SupplierCity: string;
  SupplierCountry: string;
  SupplierCreatedBy: number;
  SupplierCreatedDate: string;
  SupplierDisplayName: string;
  SupplierErpVendorId: string;
  SupplierId: number;
  SupplierIsActive: boolean;
  SupplierName: string;
  SupplierPlannerEmailid: string;
  SupplierPlannerName: string;
  SupplierPrimaryContactEmail: string;
  SupplierPrimaryContactName: string;
  SupplierState: string;
  SupplierStreetName: string;
  SupplierTypeCfgId: number;
  SupplierUpdatedBy: number;
  SupplierUpdatedDate: string;
  SupplierWebSiteUrl: string;
  SupplierZipcode: string;
  SupplierIncoterrmId: number;
  supplierTypeDomestic: boolean;
  supplierTypeOverseas: boolean;
  BaseURLToAddInEmail: string;
  SupplierPrimaryContactNumber: number;
  SupplierStatus: boolean;
  SupplierPlannerContactNo: string;
  SupplierBuyerContactNo: string;
  SupplierContactDetails: Array<SupplierContactDetail>;
  SupplierIncoTerms: Array<IncoTerms>;
  SupplierPorts: Array<PortsForSupplier>;
}


export class SupplierContactDetail {
  SupplierContactDetailsID: number;
  SupplierContactName: string;
  SupplierContactEmailid: string;
  IsEditable: boolean = false;
}

export class IncoTerms {
  key: number;
  value: string;
}

export class PortsForSupplier {
  supplierPortId: number
  portMasterId: number
  supplierId: number
  supplierPortType: boolean
  isSupplierPortIdActive: boolean  
  portName: string
  //countryCode: string
  //status: string
  //countryPortName: string
  supplierPortIdCreatedBy: number
  supplierPortIdCreatedDate: Date
  supplierPortIdUpdatedBy: number
  supplierPortIdUpdatedDate: Date
}
