import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Global } from "src/app/shared/global";
import { Agent } from "./agent.model";

@Injectable({
    providedIn: 'root'
})

export class AgentService {
    private _global: Global;
    constructor(private httpCli: HttpClient, global: Global) {
        this._global = global;
    }

    public PostAgentDetails(agentModel: Agent) {
        let userDetails = JSON.parse(localStorage.getItem('userData'));
        let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + userDetails.accessToken)
            .set('UserID', userDetails.userId);
        return this.httpCli.post<any>("" + this._global.apiBaseURl + "FetchData/PostAgent", agentModel, { headers: requestheaders });
    }

    public UpdateAgentDetails(updatedAgentModel: Agent) {
        let userDetails = JSON.parse(localStorage.getItem('userData'));
        let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + userDetails.accessToken)
            .set('UserID', userDetails.userId);
        return this.httpCli.put<any>("" + this._global.apiBaseURl + "FetchData/UpdateAgentDetails", updatedAgentModel,
            { headers: requestheaders });
    }

    public GetAgentDetailsById(agentId: number) {
        let userDetails = JSON.parse(localStorage.getItem('userData'));
        let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + userDetails.accessToken)
            .set('UserID', userDetails.userId);
        return this.httpCli.get<any>("" + this._global.apiBaseURl + "FetchData/GetAgentDetailsById?agentId=" + agentId + "",
            { headers: requestheaders });
    }

    public DeleteAgentContactDetail(contactDetailsId) {
        let userDetails = JSON.parse(localStorage.getItem('userData'));
        let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + userDetails.accessToken)
            .set('UserID', userDetails.userId);
        return this.httpCli.delete<any>("" + this._global.apiBaseURl + "FetchData/DeleteAgentContactDetail?contactDetailsId=" + contactDetailsId + "",
            { headers: requestheaders });
    }

    public DeleteAgentPort(agentPortId) {
        let userDetails = JSON.parse(localStorage.getItem('userData'));
        let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + userDetails.accessToken)
            .set('UserID', userDetails.userId);
        return this.httpCli.delete<any>("" + this._global.apiBaseURl + "FetchData/DeleteAgentPort?agentPortId=" + agentPortId + "",
            { headers: requestheaders });
    }

    public GetAgentPorts(agentId: number, selectedCountryId) {
        let userDetails = JSON.parse(localStorage.getItem('userData'));
        let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + userDetails.accessToken)
            .set('UserID', userDetails.userId);
        return this.httpCli.get<any>("" + this._global.apiBaseURl + "FetchData/GetAgentPorts?SelectedCountryId=" + selectedCountryId + "&agentId=" + agentId,
            { headers: requestheaders });
    }

    public DeleteAgentsSupplier(supplierId, agentId) {
        let userDetails = JSON.parse(localStorage.getItem('userData'));
        let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + userDetails.accessToken)
            .set('UserID', userDetails.userId);
        return this.httpCli.delete<any>("" + this._global.apiBaseURl + "FetchData/DeleteAgentsSupplier?supplierId=" + supplierId +
            "&agentId=" + agentId,
            { headers: requestheaders });
    }
}