export class Agent {
    CustomerId: number;
    AgentCity: string;
    AgentCountryCode: string;
    AgentCreatedBy: number;
    AgentCreatedDate: string;
    AgentDisplayName: string;
    AgentErpVendorId: string;
    AgentId: number;
    AgentIsActive: boolean;
    AgentName: string;
    AgentEmail: string;
    AgentPrimaryContactName: string;
    AgentState: string;
    AgentStreet: string;
    AgentUpdatedBy: number;
    AgentUpdatedDate: string;
    AgentZipcode: string;
    AgentPrimaryContactNumber: number;
    AgentStatus: boolean;
    AgentContactNo: string;
    BaseURLToAddInEmail: any;
    AgentContactDetails: Array<AgentContactDetail>;
    PortForAgent: Array<PortsForAgent>;
    AgentsSuppliers: Array<AgentsSuppliers>;
}

export class AgentContactDetail {
    AgentContactDetailsID: number;
    AgentContactName: string;
    AgentContactEmailid: string;
    IsEditable: boolean = false;
}

export class PortsForAgent {
    AgentPortId: number
    PortMasterId: number
    AgentId: number
    AgentPortType: number
    isAgentPortIdActive: number
    portName: string
    AgentPortCreatedBy: number
    AgentPortCreatedDate: Date
    AgentPortUpdatedBy: number
    AgentPortUpdatedDate: Date
}

export class AgentsSuppliers {
    SupplierId: number;
}
