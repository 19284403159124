export class User {

  public userEmailId : string;
  public userPassword : string; 
}

export class ForgotPasswordEmail {
  public userEmail : string;
  public baseURLToAddInEmail : string;
}

export class ValidateResetPasswordDetail {
  public userEmail : string;
  public systemPassword : string;
  public resteID : number;
}

export class ResetPassword {
  public newPassword : string;
  public reEnterPassword : string;
  public resteID : number;
}

export class UpdatePassword {
  public newPassword: string;  
  public userId: number;
}

export class ContactUs {

  public contactUsId: number;
  public senderEmailId: string;
  public receiverEmailId: string;
  public ccEmailId: string;
  public messageText: string;
  public userId: number;
  public isMailFromLoginPage: boolean;
}
