
export class TUser {
  public userId: number;
  public userEntityIdentifier: any;
  public userEntityId: number;
  public userFirstName: string;
  public userMasterLastName: string;
  public userEmailId: string;
  public userPassword: string;
  public userStatusCfgId: number;
  public roleMasterId: number;
  public userMasterIsActive: number;
  public userMasterCreatedDate: Date;
  public userMasterCreatedBy: number;
  public userMasterUpdatedDate: Date;
  public userMasterUpdatedBy: number;
  public userMasterDepartmentName: string;
  public baseUrl: string;
  public internalUserLogingId: number;
}

export class InternalUser{
  public userId: number;
  public loggedInUserId: number;
}

export class BillToInfo {
  public customerBilledToInformationId: number;
  public customerId: number
  public billedToName: string
  public billedToAddressLine1: string
  public billedToAddressLine2: string
  public billedToCity: string
  public billedToState: string
  public billedToZipcode: string
  public customerBilledToInformationIsActive: boolean
  public customerBilledToInformationCreatedBy: number
  public customerBilledToInformationCreatedDate: Date
  public customerBilledToInformationUpdatedBy: number
  public customerBilledToInformationUpdatedDate: Date
}

export class SoldToInfo {
  public customerSoldToInformationId: number;
  public customerId: number
  public soldToName: string
  public soldToAddressLine1: string
  public soldToAddressLine2: string
  public soldToCity: string
  public soldToState: string
  public soldToZipcode: string
  public customerSoldToInformationIsActive: boolean
  public customerSoldToInformationCreatedBy: number
  public customerSoldToInformationCreatedDate: Date
  public customerSoldToInformationUpdatedBy: number
  public customerSoldToInformationUpdatedDate: Date
}

export class POOtherDetails {
  public customerPurchaseOrderInformationId: number;
  public customerId: number
  public poPaymentTerms: string
  public poCurrency: string
  public poSpecialCondtion: string
  public poSpecialInstructions: string
  public customerPurchaseOrderInformationCreatedBy: number
  public customerPurchaseOrderInformationCreatedDate: Date
  public customerPurchaseOrderInformationUpdatedBy: number
  public customerPurchaseOrderInformationUpdatedDate: Date
}



export class CustomerPortOrOrigin {
  public portsForCustomerId: number;
  public customerId: number
  public portId: number
  public portName: string
  public portForCustomerCreatedBy: number
  public portForCustomerCreatedDate: Date
  public portForCustomerUpdatedBy: number
  public portForCustomerUpdatedDate: Date
}

