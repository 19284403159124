import { Component, OnInit,ElementRef,ViewChild  } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.css']
})
export class SiteLayoutComponent implements OnInit {
  //@ViewChild('mainScreen') elementView: ElementRef;
  navHeight:any;
  constructor(private _route: Router) { }
  //userDetails = JSON.parse(localStorage.getItem('userData'));
  ngOnInit() {
    // 
    //// if(this.userDetails == null)
    ////   {
    ////     this._route.navigate(['/login']); 
    ////     return;
    ////   }
    //var navHt = $('.custom-nav').height();
    //var ftrHt = $('footer').innerHeight();
    //$('#scrollable-area').height( $(window).innerHeight() - ( navHt + ftrHt) );
    //$('#scrollable-area').css('top', navHt);
  }

}
