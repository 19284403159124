import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Headers, Http } from '@angular/http';
import { Global } from 'src/app/shared/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SupplierInternalUserService {

  //userDetails = JSON.parse(localStorage.getItem('userData'));
  public userDetails: any;
  private _global: Global;
  constructor(private http: Http, private global: Global, private httpCli:HttpClient) { 
    this._global = global;
  }


  public showAllUsersExceptLoggedIn(internalUser):Observable<any>
  {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
                    .set('Authorization', 'Bearer ' + internalUser.accessToken)
                    .set('UserID', this.userDetails.userId);

     return this.httpCli.get<any>("" + this._global.apiBaseURl + "FetchData/GetAllTheUsersExceptLoggedIn?userID="+internalUser.userId+"",
       { headers: requestheaders});
  }

  public getSupplierInternalUserDetails(supplierId,isSupplier): Promise<any> 
  {
  this.userDetails = JSON.parse(localStorage.getItem('userData'));
  let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Supplier/GetSupplierInternalUserList?supplierId=" + supplierId+"&IsSupplier="+isSupplier, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  public addSupplierInternalUser(supplierInternalUserDetails: any): Promise<any>
  {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.post("" + this._global.apiBaseURl + "Supplier/InsertOrUpdateSupplierInternalUser", supplierInternalUserDetails, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  public deleteSupplierInternalUser(internalUser): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.post("" + this._global.apiBaseURl + "Supplier/DeleteSupplierInternalUser", internalUser, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

}

