import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { Global } from 'src/app/shared/global';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  _global: Global;
  //userDetails = JSON.parse(localStorage.getItem('userData'));
  public userDetails: any;
  public userdata: any;
  private requestUrl = "";
  //all header add by vivek30/8/2019
  constructor(private http: Http, private global: Global) {
    this._global = global;
  }

   //Added by Varsha on 04-09-2019
  //To Update Customer Details By Login UserId
  updateCustomerDetails(customerdetails: any): Promise<any> {
    try {
      this.userDetails = JSON.parse(localStorage.getItem('userData'));
      let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
      return this.http.post("" + this._global.apiBaseURl + "Customer/UpdateCustomer", customerdetails, { headers: headers })
        .toPromise()
        .then(response => response.json() as any)
        .catch(respose => respose.json() as any)
    } catch (e) {
      console.debug(e);
    }
  }

   //Added by Varsha on 21-08-2019
  //To Get Title List Form from MCfgMasterDetails table
  public getTitlelist(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Customer/GetTitleFromMCfgMasterDetails",{ headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Varsha on 21-08-2019
  //To Get TimeZone List Form from MCfgMasterDetails table
  public getTimeZonelist(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Customer/GetTimeZoneFromMCfgMasterDetails", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

   //Added by Varsha on 21-08-2019
  //To Get DateFormat List Form from MCfgMasterDetails table
  public getDateFormatlist(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Customer/GetDateFormatFromMCfgMasterDetails", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

   //Added by Varsha on 05-09-2019
  //To Edit Customer Details
  getCustomerDetailsById(customerId: number): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Customer/GetCustomerDetalisByCustomerId?customerId=" + customerId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  resetAPIKey(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Customer/ResetAPIKey", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }
}
