import { Component, OnInit } from '@angular/core';
import { ContactUs } from '../../../login/user';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../../login/shared/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormlabelsService } from '../../../shared/formlabels.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading: boolean = false;
  public contactUs: ContactUs = null;
  public userDetails: any;
  public pageLabels: any = [];


  constructor(private toastr: ToastrService, private _loginService: LoginService, private _route: Router,
    private toastrService: ToastrService, private formLabelService: FormlabelsService) {

    this.contactUs = new ContactUs();
  }

  ngOnInit() {
    this.getAllLabelsAndMessages();
  }
  today: number = Date.now();


  //Added by Varsha on 16-10-2019
  //To Add Contact Us in t_contact_us table.
  public InsertContactUs() {
    this.loading = true;
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.contactUs.userId = this.userDetails.userId;

    //Added by Mahesh on 02-04-2020
    console.log(window.location.href.indexOf('login') > 0);
    if (window.location.href.indexOf('login') > 0) {
      this.contactUs.isMailFromLoginPage = true;
    } else {
      this.contactUs.isMailFromLoginPage = false;
    }
    console.log(this.contactUs.isMailFromLoginPage);
    //End 02-04-2020
    this._loginService.InsertContactUs(this.contactUs)
      .then((result) => {
        try {
          if (result.isSuccessful == true) {

            this.toastr.success(result.message, "Success", { timeOut: 5000 });
            this.loading = false;
            window.location.reload();
          }
          else if (result.isSuccessful == false) {
            this.loading = false;
            this.toastr.error(result.message, "Error", { timeOut: 5000 });
          }
          else {
            this.loading = false;
            this.toastr.error("Failed to connect to the server", "Error", { timeOut: 5000 });
          }
        } catch (Error) {

        }
      })
  }

  //added by Kunal on 13/8/2020 get labels from db mlocalstring
  async getAllLabelsAndMessages() {
    this.formLabelService.getAllLabelsAndMessages()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.pageLabels = [];
            for (let i = 0; i <= result.data.length - 1; i++) {
              this.pageLabels.push(result.data[i].resourceValue);
            }
          }
          else if (result.isSuccessful == false) {
            this.toastrService.error(result.message, "", { timeOut: 5000 });
          }
          else {
            this.toastrService.error("Failed to connect to the server", "", { timeOut: 5000 });
          }
        } catch (Error) {
        }
      })
      .catch((err) => {
      })
  }
}
