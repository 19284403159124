import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { Global } from '../../shared/global';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../user';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

Global
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  _global: Global;


  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: Http, private global: Global, private httpcli: HttpClient) {
    this._global = global;

    //Shailesh
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }


  //Shailesh
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public login(user: any): Promise<any> {

    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post("" + this._global.apiBaseURl + "authentication/login", user, { headers: headers })
      .toPromise()
      .then(

        response => {
          let userData = response.json() as any;
          this.currentUserSubject.next(userData);
          return (userData);
        }


      )
      .catch(response => {
        console.log(response);
        response.json() as any;
      });



  }

  // Code changed for optimization.
  public servicelogin(user: any): Promise<any> {
    return this.returnResponseFromApi(user, "authentication", "login");
  }

  //Added by Mahesh
  //To get reset password detail email
  public getResetPasswordDetailEmail(forgotPasswordEmail: any): Promise<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post("" + this._global.apiBaseURl + "forgotpassword/checkforemailexistence", forgotPasswordEmail, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(response => response.json() as any);
  }

  //Added by Mahesh
  //To validate reset password details
  public validateResetPasswordDetaild(validateResetPasswordDetail: any): Promise<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post("" + this._global.apiBaseURl + "forgotpassword/ValidateCredentialsForResetPassword", validateResetPasswordDetail, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(response => response.json() as any);
  }

  //Added by Mahesh
  //To reset password
  public resetPassword(resetPassword: any): Promise<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post("" + this._global.apiBaseURl + "forgotpassword/ResetPassword", resetPassword, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(response => response.json() as any);
  }

  //Added by Mahesh
  //To get user data
  public getUserData(resetPassword: any): Promise<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post("" + this._global.apiBaseURl + "forgotpassword/GetUserData", resetPassword, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(response => response.json() as any);
  }

  //Added by Mahesh
  //To update intrnal user password
  public updateIntrnalUserPassword(updatePassword: any): Promise<any> {

    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post("" + this._global.apiBaseURl + "authentication/UpdateInternalUserPassword", updatePassword, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(response => response.json() as any);
  }


  returnResponseFromApi(inputObject: any, apiControllerName: string, apiFunctionName: string): Promise<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post("" + this._global.apiBaseURl + "" + apiControllerName + "/" + apiFunctionName + "", inputObject, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(response => response.json() as any);

  }


  //Added by Varsha
  //To Insert or update ContactUs in t_contact_us table.
  public InsertContactUs(contactUs: any): Promise<any> {

    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post("" + this._global.apiBaseURl + "Authentication/InsertContactUs", contactUs, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch((response) => {

        console.log(response);
      });
  }

  //Added by Shailesh
  public CheckForUserAccess1(userID: number, menuName: string): Promise<any> {

    let headers = new Headers({ 'Content-Type': 'application/json' });
    var accessDetails = {
      _userID: userID,
      _menuName: menuName
    };
    return this.http.post("" + this._global.apiBaseURl + "Authentication/CheckForUserAccess", accessDetails, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch((response) => {

        console.log(response);
      });


  }

  public CheckForUserAccess(userID: number, menuName: string): Observable<any> {

    let headers = new Headers({ 'Content-Type': 'application/json' });
    var accessDetails = {
      _userID: userID,
      _menuName: menuName
    };
    return this.httpcli.post("" + this._global.apiBaseURl + "Authentication/CheckForUserAccess", accessDetails)



  }

  public getEmailIdAfterResetPassword(requestID: any): Promise<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.get("" + this._global.apiBaseURl + "forgotpassword/GetEmailIDByRequestID?requestID=" + requestID, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(response => response.json() as any);
  }

}
