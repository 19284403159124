import { Component, OnInit, ViewEncapsulation, Input, ElementRef } from '@angular/core';
import { ModalWindowService } from '../modal-window.service';

@Component({
  selector: 'jw-modal',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ModalWindowComponent implements OnInit {
  @Input() id: string;
  @Input() modalwidth:string;
  private element: any;
  public width:string;
  constructor(private modalService: ModalWindowService, private el: ElementRef) {
    
    this.element = el.nativeElement;
    
  }

  ngOnInit() {
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

   // this.width = this.modalService.width;
    this.width=this.modalwidth;
    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', el => {
      if (el.target.className === 'jw-modal') {
        //this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);

  }


  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

}
