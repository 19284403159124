import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MenuService } from 'src/app/layout/site-layout/menu/shared/menu.service';
import { ConfirmationDialogService } from 'src/app/shared/delete-confirmation/confirmation-dialog.service';

@Component({
  selector: 'app-po-notification',
  templateUrl: './po-notification.component.html',
  styleUrls: ['./po-notification.component.css']
})
export class PoNotificationComponent implements OnInit {

  public notificationData:any;
  public userDetails:any;
  public dtOptions: any = {};

  constructor(private _menuService: MenuService,private toastr: ToastrService,private confirmationDialogService: ConfirmationDialogService,) { }

  ngOnInit() {
    this.dtOptions = {
      data: this.notificationData,
      pagingType: 'full_numbers',
      scrollX: true,
      dom: 'Bfrtip'
    };
    this.userDetails = JSON.parse(localStorage.getItem('userData'));

    this.GetPos();
  }

  public GetPos()
  {
    // let userId: number = this.userDetails.userId;
    // 
    // this._menuService.getPoNotification(userId)
    //     .then((result) => {
    //       if (result.isSuccessful == true) {
    //         this.notificationData = [];
    //         this.notificationData = result.data;
    //       }
    //       else {
    //         this.notificationData = [];
    //       }
    //     }).catch((result) => {
    //       this.notificationData = [];
    //       console.log(result);
    //     })
  }

  public ReadPo(notificationId:Number, notificationDescription:string)
  {
    /*let userId: number = this.userDetails.userId;
    //this.toastr.info("Po read");
    this.confirmationDialogService.confirm("Po Description",notificationDescription,"Mark as Read")
      .then(
        (confirmed) => {
          if (confirmed) {
            this._menuService.updateNotification(notificationId)
            .then((result)=> {
              if(result.isSuccessful == true){
                this.GetPos();
                this._menuService.getPoNotification(userId);
                console.log("Update successfull");
                this.toastr.success("Updated Notification Status");
                window.location.reload();//to get the
              }
              else{
                console.log("Update failed");
                this.toastr.error("Failed to update Notification Status");
              }
            })
            console.log("confirm/yes");
          }
          else{
            console.log("rejected/cancel");
          }
        })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));*/
  }
}
