import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { Global } from 'src/app/shared/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InternaluserService {
  //userDetails = JSON.parse(localStorage.getItem('userData'));
  public userDetails: any;
  public userdata: any;
  private requestUrl = "";
  private _global: Global;

  constructor(private http: Http, private global: Global, private httpCli: HttpClient) {
    this._global = global;
  }

  //Added by Varsha on 29-08-2019
  //To Get Role From MRoleMaster Table
  public getUserRole(userId): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "User/GetRoleMaster?userId=" + userId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Varsha on 29-08-2019
  //To Get Role From m_cfg_master_detail Table.
  public getUserStatus(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "User/GetUserStatus", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Varsha on 09-09-2019
  //To Add Internal User in TUser Table.
  insertInternalUser(internalUserDetails: any): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.post("" + this._global.apiBaseURl + "User/InsertOrUpdateInternalUser", internalUserDetails, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Varsha on 09-09-2019
  //To Get Internal User Deatils For Binding To The Datatable.
  public getInternalUserDetails(userId): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "User/GetAllInternalUser?UserId=" + userId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }


  //Added by Varsha on 09-09-2019
  //To Get Internal User Deatils For Edit.
  public getInternalUserDetailsForEdit(userId): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "User/GetInternalUserDetailsForEdit?UserId=" + userId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Varsha on 09-09-2019
  //To Update Internal User in TUser Table.
  public deleteInternalUser(internalUser): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.post("" + this._global.apiBaseURl + "User/DeleteInternalUser", internalUser, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Varsha on 09-09-2019
  //To Update Internal User in TUser Table.
  public enableInternalUser(internalUser): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.post("" + this._global.apiBaseURl + "User/EnableInternalUser", internalUser, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Shailesh - 30/09/2019
  public serviceShowAllUsersExceptLoggedIn(internalUser): Observable<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + internalUser.accessToken)
      .set('UserID', this.userDetails.userId);

    return this.httpCli.get<any>("" + this._global.apiBaseURl + "FetchData/GetAllTheUsersExceptLoggedIn?userID=" + internalUser.userId + "",
      { headers: requestheaders });
  }

//Added by Mahesh on 22-10-2019
  //To update the supplier's status to active or inactive 
  public enableOrDissableSupplier(supplierId, loggedInUserId): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Supplier/EnableOrDisableSupplier?SupplierId="+ supplierId+"&LoggedInUserId="+loggedInUserId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }


  //Added by Mahesh on 25-05-2020
  //To add or update the bill to info
  public addOrUpdateBillToInfo(billToInfo): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.post("" + this._global.apiBaseURl + "User/AddOrUpdateBillToInfo", billToInfo, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Mahesh on 25-05-2020
  //To add or update the sold to info
  public addOrUpdateSoldToInfo(soldToInfo): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.post("" + this._global.apiBaseURl + "User/AddOrUpdateSoldToInfo", soldToInfo, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Mahesh on 25-05-2020
  //To add or update the po other details
  public addOrUpdatePOOtherDetails(poOtherDetails): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.post("" + this._global.apiBaseURl + "User/AddOrUpdatePOOtherDetails", poOtherDetails, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Mahesh on 25-05-2020
  //To get the bill to, sold to and po other details
  public getCustomerAddressess(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "User/GetCustomerAddressess", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Mahesh on 01-06-2020
  //To add or update the po other details
  public insertOrUpdateSelectedPortsOriginForCustomer(selectedPortsOriginListForCustomer): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.post("" + this._global.apiBaseURl + "User/InsertSelectedPortsOriginForCustomer", selectedPortsOriginListForCustomer, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Mahesh on 01-06-2020
  //To add or update the po other details
  public getSelectedPortsOriginsForCustomerToDisplay(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "User/GetSelectedPortsOriginsForCustomer", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Mahesh on 01-06-2020
  //To add or update the po other details
  public deleteCustomerPortOrigin(portForCustomerId): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "User/DeleteCustomerPortOrigin?PortForCustomerId=" + portForCustomerId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

   //Added Omkar 30th June 2020
  //To Get Currency list From m_cfg_master_detail Table.
  public getCurrencyList(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "User/GetListOfCurrencies", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }
  
}
