import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../login/shared/login.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private routes: Router, private loginService: LoginService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let userData = JSON.parse(localStorage.getItem('userData'));
    if (userData !== null) {

      if (next.routeConfig.path == '') {
        return true;
      }


      //check if the user hass access to the router
      return new Promise((data) => {
        this.loginService.CheckForUserAccess(userData.userId, next.routeConfig.path).subscribe((response) => {
          if (response.data) {
            data(true);
          }
          else {
            this.routes.navigate(['/login']);
            data(false);
          }
        }, (error) => {
          this.routes.navigate(['/login']);
          data(false);
        });
      });



    }
    else {
      this.routes.navigate(['/login']);
      return false;
    }
  }



  canLoad(route: Route): boolean {

    //determine whether you want to load the module
    //return true or false
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let userData = JSON.parse(localStorage.getItem('userData'));
    if (userData !== null) {

      if (childRoute.routeConfig.path == '') {
        return true;
      }


      //check if the user hass access to the router
      return new Promise((data) => {
        this.loginService.CheckForUserAccess(userData.userId, childRoute.routeConfig.path).subscribe((response) => {
          if (response.data) {
            data(true);
          }
          else {
            this.routes.navigate(['/login']);
            data(false);
          }
        }, (error) => {
          this.routes.navigate(['/login']);
          data(false);
        });
      });



    }
    else {
      this.routes.navigate(['/login']);
      return false;
    }
  }
}
