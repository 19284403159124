import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Customer, TUser, TSupplier, ResetPassword } from './Customer';
import { CustomerService } from '../my-profile/Shared/customer.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { FormlabelsService } from '../../app/shared/formlabels.service'
import { from } from 'rxjs';
//import { MenuComponent } from '../layout/site-layout/menu/menu.component';


@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})

export class MyProfileComponent implements OnInit {

  public customer: any;
  public tUser: any;
  public tUserOtherDatail: any;
  public tSupplier: any;
  public photodetails: any;
  dropdownSettings: any;
  TitleDescription: any[];
  TimeZoneDetails: any[];
  DateFormat: any[];
  resetPassword: any;
  public mystring: any;
  public show: boolean = true;
  public disable: boolean = true;
  photourl: any;

  message: any;
  imagePath: any;
  imgURL: any;
  btnOutline: boolean = true; //hide show password
  //public userDetails = JSON.parse(localStorage.getItem('userData'));
  public userDetails: any;

  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading: boolean = false;
  public pageLabels: any = [];


  constructor(private _customerService: CustomerService, private toastr: ToastrService, private _route: Router,
    private toastrService: ToastrService, private formLabelService: FormlabelsService) { }

  ngOnInit() {
    this.customer = new Customer();
    this.tUser = new TUser();
    this.tSupplier = new TSupplier();
    this.resetPassword = new ResetPassword();
    this.getTitleList();
    this.getTimeZoneList();
    this.getDateFormatList();
    this.SendCustomerDetails();
    this.getAllLabelsAndMessages();
  }

  //Added by Varsha on 21-08-2019
  //To Update Customer Data in Customer Table.
  public POSTUpdateCustomer() {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.loading = true;
    if (this.customer.userMasterDepartmentName != undefined) {
      this.customer.userMasterUpdatedBy = this.userDetails.userId;
      this._customerService.updateCustomerDetails(this.customer)
        .then((result) => {

          if (result.isSuccessful == true) {

            document.body.querySelector('img').src = result.data.customerLogoOriginalFilePath;
            this.toastr.success(result.message, "", { timeOut: 5000 });
            this.loading = false;
            this._route.navigate(['/dashboard']);
          }
          else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
            this.toastr.error(result.message, "", { timeOut: 5000 });
            this.loading = false;
            localStorage.removeItem('userData');
            this._route.navigate(['/']);
          } else if (result.isSuccessful == false) {
            this.toastr.error(result.message, "", { timeOut: 5000 });
            this.loading = false;
          } else {
            this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
            this.loading = false;
          }
        })
    } else {
      this.toastr.error(this.pageLabels[773], "", { timeOut: 5000 });//Please update department name
    }
  }


  //Added by Varsha on 21-08-2019
  //To Get Title List Form from MCfgMasterDetails table
  async getTitleList() {
    this._customerService.getTitlelist()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.TitleDescription = result.data;
          }
          else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
            this.toastr.error(result.message, "", { timeOut: 5000 });
            localStorage.removeItem('userData');
            this._route.navigate(['/']);
          } else if (result.isSuccessful == false) {
            this.toastr.error(result.message, "", { timeOut: 5000 });
          } else {
            this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          }
        } catch (Error) {

        }
      })
  }

  //Added by Varsha on 21-08-2019
  //To Get TimeZone List Form from MCfgMasterDetails table
  async getTimeZoneList() {
    this._customerService.getTimeZonelist()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.TimeZoneDetails = result.data;
          }
          else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
            this.toastr.error(result.message, "", { timeOut: 5000 });
            localStorage.removeItem('userData');
            this._route.navigate(['/']);
          } else if (result.isSuccessful == false) {
            this.toastr.error(result.message, "", { timeOut: 5000 });
          } else {
            this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          }
        } catch (Error) {

        }
      })
  }

  //Added by Varsha on 21-08-2019
  //To Get DateFormat List Form from MCfgMasterDetails table
  async getDateFormatList() {
    this._customerService.getDateFormatlist()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.DateFormat = result.data;
          }
          else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
            this.toastr.error(result.message, "", { timeOut: 5000 });
            localStorage.removeItem('userData');
            this._route.navigate(['/']);
          } else if (result.isSuccessful == false) {
            this.toastr.error(result.message, "", { timeOut: 5000 });
          } else {
            this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          }
        } catch (Error) {

        }
      })
  }

  //Added by Varsha on 25-08-2019
  //To Get Customer Logo Image And Convert Into Base64
  detectFiles(event) {
    let files = event.target.files;
    let fileExtention = event.target.files[0].name.split('.')[1];
    let mimetype = files[0].type;
    if (files) {

      if (mimetype.match(/image\/*/) == null) {
        this.toastr.error(this.pageLabels[774], "", { timeOut: 5000 });//Please select valid image file
        this.customer.logoBase64ImageString = null;
        this.customer.logoImageName = null;
        return;
      }
      let file = files[0];
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.mystring = e.target.result;
        if (mimetype == "image/jpeg") {
          let picstring = this.mystring.replace('data:image/jpeg;base64,', ' ');
          this.customer.logoBase64ImageString = picstring;
          this.customer.logoImageName = files[0].name;
        }
        else if (mimetype == "image/jpg") {
          let picstring = this.mystring.replace('data:image/jpg;base64,', ' ');
          this.customer.logoBase64ImageString = picstring;
          this.customer.logoImageName = files[0].name;
        }
        else if (mimetype == "image/png") {
          let picstring = this.mystring.replace('data:image/png;base64,', ' ');
          this.customer.logoBase64ImageString = picstring;
          this.customer.logoImageName = files[0].name;
        }
        else if (mimetype == "image/gif") {
          let picstring = this.mystring.replace('data:image/gif;base64,', ' ');
          this.customer.logoBase64ImageString = picstring;
          this.customer.logoImageName = files[0].name;
        }
      }
      if (file && file.type.match('image.*')) {
        reader.readAsDataURL(file);
      }
    }
    //Priview Image code.
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.imgURL = reader.result;
      }
    }
  }

  //Added by Varsha on 30-08-2019
  //To Edit Customer Details
  SendCustomerDetails() {
    this.loading = true;
    this.show = !this.show; //Remove Password And Re-Enter Password In Edit Mode.
    this.disable = !this.disable;
    this.userDetails = JSON.parse(localStorage.getItem('userData'));

    if (this.userDetails.userId == undefined) {

    }
    else {
      this._customerService.getCustomerDetailsById(this.userDetails.userId)
        .then((result) => {
          try {
            if (result.isSuccessful == true) {
              this.loading = false;
              this.customer = result.data;
              this.imgURL = this.customer.customerLogoOriginalFilePath;
            }
            else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
              this.loading = false;
              this.toastr.error(result.message, "Error", { timeOut: 5000 });
              localStorage.removeItem('userData');
              this._route.navigate(['/']);
            } else if (result.isSuccessful == false) {
              this.loading = false;
              this.toastr.error(result.message, "Error", { timeOut: 5000 });
            } else {
              this.loading = false;
              this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
            }
          } catch (Error) {
            alert(Error.message);
          }
        })
    }
  }

  //added by Kunal on 11/8/2020 get labels from db mlocalstring
  async getAllLabelsAndMessages() {
    this.formLabelService.getAllLabelsAndMessages()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.pageLabels = [];
            for (let i = 0; i <= result.data.length - 1; i++) {
              this.pageLabels.push(result.data[i].resourceValue);
            }
          }
          else if (result.isSuccessful == false) {
            this.toastrService.error(result.message, "", { timeOut: 5000 });
          }
          else {
            this.toastrService.error("Failed to connect to the server", "", { timeOut: 5000 });
          }
        } catch (Error) {
        }
      })
      .catch((err) => {

      })
  }

  //Added by Mahesh on 06-09-2020
  resetAPIKey() {
    this._customerService.resetAPIKey().then((result) => {
      try {
        if (result.isSuccessful == true) {
          this.loading = false;
          this.customer.customerApiKey = result.data;
          this.toastr.clear();
          this.toastr.success(result.message, "Success", { timeOut: 5000 })
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        } else if (result.isSuccessful == false) {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
        } else {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
        }
      } catch (Error) {
        alert(Error.message);
      }
    })
  }

  /* Added by Mahesh on 06-09-2020, To copy API key to clipboard from Textbox */

  copyAPIKey(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.clear();
    this.toastr.success(this.pageLabels[507], "Success", { timeOut: 5000 });
  }
}

