import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/filter';
import { BookingsService } from 'src/app/bookings/bookings-service.service';
import { Agent, AgentContactDetail, PortsForAgent } from '../agent.model';
import { ConfirmationDialogService } from 'src/app/shared/delete-confirmation/confirmation-dialog.service';
import { SupplierServiceService } from '../../supplier/supplier-service.service';
import { SupplierContactDetail } from '../../supplier/supplierForInsertion';
import { AgentService } from '../agent.service';
@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css']
})

export class AgentComponent implements OnInit {
  private readonly ISSTRINGEMPTY = /^\s*$/;

  AgentIDFromQS: number = 0;
  AgentDetails: Agent;
  contactNameForAdd: string;
  contactEmailForAdd: string;
  disableAgentEmailFeild: boolean = true;
  ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  loading: boolean = false;
  countryList: any;
  userDetails: any;
  portNameList: any = [];
  portList: any = [];
  AgentPortList: PortsForAgent[] = [];
  addPortList: any[] = [];
  portName: any = null;
  portMasterId: any = null;
  disablePortList: boolean;
  SelectedCountry: any;
  selectedPortsListForAgent: PortsForAgent[] = [];
  originName: any = null;
  originMasterId: any = null;
  editableRow: any;
  showSavebtn: boolean = false;
  isDomesticTextCheck: boolean = false;
  isOverseasTextCheck: boolean = false;
  modeFromQS: string;
  editViewText: string;

  pageTitle = "";
  supplierList: any;
  selectedSuppliersListForAgent: any = [];
  currentSupplierKey: any;
  constructor(private _location: Location, private route: ActivatedRoute,
    private agentService: AgentService,
    private toastr: ToastrService, private confirmationDialogService: ConfirmationDialogService,
    private _route: Router, private supplierServiceService: SupplierServiceService,
    private bookingsService: BookingsService) {
    this.AgentDetails = new Agent();
    this.AgentDetails.AgentContactDetails = new Array<AgentContactDetail>();
    this.AgentDetails.PortForAgent = new Array<PortsForAgent>();
    this.AgentIDFromQS = 0;
    this.route.queryParams.subscribe((data) => {
      this.AgentIDFromQS = data["AgentId"];
      this.modeFromQS = data["mode"];
      this.editViewText = data["mode"] == 'view' ? 'Edit' : 'View';
    });
    this.contactNameForAdd = "";
  }

  ngOnInit() {
    this.pageTitle = this.modeFromQS == null ? "Add agent" : this.modeFromQS + " agent";
    this.loading = true;
    this.GetCoutryList();
    this.GetPorts();
    this.GetDropdownValues();
    this.loading = false;
    if (this.AgentIDFromQS != 0 && this.modeFromQS != null) {
      this.loading = true;
      this.GetTheAgentDetails(this.AgentIDFromQS);
    }
  }

  btnGoBack() {
    this._location.back();
  }

  GetTheAgentDetails(agentID: number) {
    this.disablePortList = true;
    this.agentService.GetAgentDetailsById(agentID).subscribe((response) => {
      console.log(response);
      if (response.isSuccessful) {
        this.loading = false;
        this.toastr.success("Agent Details Loaded Successfully", "Success");
        var data = response.data;
        console.log(" Agent Data", data);
        this.AgentDetails = new Agent();
        this.AgentDetails.AgentContactDetails = new Array<AgentContactDetail>();
        this.AgentDetails.AgentCity = data.agentCity;
        this.AgentDetails.AgentContactNo = data.agentContactNo;
        this.AgentDetails.AgentCountryCode = data.agentCountryCode;
        this.AgentDetails.AgentDisplayName = data.agentDisplayName;
        this.AgentDetails.AgentErpVendorId = data.agentErpVendorId;
        this.AgentDetails.AgentId = data.agentId;
        this.AgentDetails.AgentName = data.agentName;
        this.AgentDetails.AgentEmail = data.agentEmail;
        this.AgentDetails.AgentPrimaryContactName = data.agentPrimaryContactName;
        this.AgentDetails.AgentPrimaryContactNumber = data.agentContactNo;
        this.AgentDetails.AgentState = data.agentState;
        this.AgentDetails.AgentStreet = data.agentStreet;
        this.AgentDetails.AgentZipcode = data.agentZipCode;
        this.AgentDetails.AgentStatus = data.agentStatus;

        //contact details
        data.agentContactDetails.forEach(element => {
          this.AgentDetails.AgentContactDetails.push({
            AgentContactDetailsID: element.agentContactDetailsId,
            AgentContactName: element.agentContactName,
            AgentContactEmailid: element.agentContactEmailId,
            IsEditable: false
          });
        });

        //port details
        this.AgentDetails.PortForAgent = [];
        data.portForAgent.forEach(element => {
          element.AgentPortType = element.agentPortType == 0 ? false : true;
        });
        this.AgentDetails.PortForAgent = data.portForAgent;
        this.selectedPortsListForAgent = [];
        data.portForAgent.forEach(e => {
          let portForAgent = new PortsForAgent();
          portForAgent.AgentPortId = e.agentPortId;
          portForAgent.PortMasterId = e.portMasterId;
          portForAgent.AgentId = e.agentId;
          portForAgent.AgentPortType = e.agentPortType;
          portForAgent.isAgentPortIdActive = e.isAgentPortIdActive;
          portForAgent.portName = e.portName;
          this.selectedPortsListForAgent.push(portForAgent);
        });

        //supplier details
        this.selectedSuppliersListForAgent = [];
        this.AgentDetails.AgentsSuppliers = [];
        data.agentsSuppliers.forEach(element => {
          this.selectedSuppliersListForAgent.push({
            Name: element.supplierName,
            Key: element.supplierId
          });

          this.AgentDetails.AgentsSuppliers.push({
            SupplierId: element.supplierId
          });
        });
      }
    },
      (error) => {
        this.loading = false
        console.log(error);
        this.toastr.error(error.message, error.name);
      }
    );
    this.disableAgentEmailFeild = false;
  }

  ValidateEmailId(): Promise<any> {
    return new Promise((data) => {
      let enteredEmailAddress: string = this.contactEmailForAdd;
      this.AgentDetails.AgentContactDetails.forEach((element) => {
        if (element.AgentContactEmailid == enteredEmailAddress) {
          data(false);
          return false;
        }
      });
      data(true);
    });
  }

  AddContactDetails() {
    if ((this.ISSTRINGEMPTY.test(this.contactNameForAdd) || this.contactNameForAdd == null) &&
      (this.ISSTRINGEMPTY.test(this.contactEmailForAdd) || this.contactEmailForAdd == null)) {
      this.toastr.warning("Email id and Name cannot be empty.");
      return;
    }
    if (this.ISSTRINGEMPTY.test(this.contactNameForAdd) || this.contactNameForAdd == null) {
      this.toastr.warning("Name cannot be empty.");
      return;
    }
    if (this.ISSTRINGEMPTY.test(this.contactEmailForAdd) || this.contactEmailForAdd == null) {
      this.toastr.warning("Email cannot be empty.");
      return;
    }
    this.ValidateEmailId()
      .then((data) => {
        if (data) {
          let newSupplierContactDetailsID = -(this.AgentDetails.AgentContactDetails.length + 1);
          this.AgentDetails.AgentContactDetails.push({
            AgentContactDetailsID: newSupplierContactDetailsID,
            AgentContactName: this.contactNameForAdd,
            AgentContactEmailid: this.contactEmailForAdd,
            IsEditable: false
          });
          this.contactNameForAdd = '';
          this.contactEmailForAdd = '';
        }
        else {
          this.toastr.warning("The entered email address is already used");
        }
      })
      .catch(() => {
        this.toastr.error("Something went wrong", "Add contact details");
      })
  }

  POSTUpdateAgent() {
    this.loading = true;
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.AgentDetails.AgentCreatedBy = this.userDetails.userId;
    this.AgentDetails.AgentUpdatedBy = this.userDetails.userId;
    this.AgentDetails.PortForAgent = this.selectedPortsListForAgent;
    this.AgentDetails.BaseURLToAddInEmail = window.location.protocol + "//" + window.location.host;

    this.AgentDetails.AgentsSuppliers = []
    this.selectedSuppliersListForAgent.forEach(e => {
      this.AgentDetails.AgentsSuppliers.push({
        SupplierId: e.Key
      });
    });
    this.agentService.PostAgentDetails(this.AgentDetails)
      .subscribe(
        (data) => {
          if (data.isSuccessful) {
            this.toastr.clear();
            this.toastr.success(data.message, "Success");
            this.loading = false;
            this._route.navigate(['/internaluser']);
          }
          else {
            this.toastr.clear();
            this.toastr.error(data.message, "Error");
            this.loading = false;
          }
        },
        (error) => {
          this.toastr.clear();
          this.toastr.error("Something went wrong.");
          this.loading = false;
        }
      );
  }

  DeleteContactDetails(contact: AgentContactDetail) {
    this.confirmationDialogService.confirm("Contact delete confirmation", "Do you really want to delete this contact?")
      .then(
        (confirmed) => {
          if (confirmed) {
            if (contact.AgentContactDetailsID > 0) {
              this.agentService.DeleteAgentContactDetail(contact.AgentContactDetailsID)
                .subscribe((result) => {
                  this.toastr.clear();
                  if (result.isSuccessful) {
                    this.toastr.success(result.message, "Success");
                  }
                  else {
                    this.toastr.error(result.message, "Error", { timeOut: 5000 });
                  }
                });
            }
            for (var i = 0; i <= this.AgentDetails.AgentContactDetails.length - 1; i++) {
              if (contact.AgentContactDetailsID == this.AgentDetails.AgentContactDetails[i].AgentContactDetailsID) {
                this.AgentDetails.AgentContactDetails.splice(i, 1);
              }
            }
          }
        })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  GetCoutryList() {
    this.supplierServiceService.getCountryPortList().then(result => {
      if (result.isSuccessful == true) {
        this.countryList = result.data;
      }
      else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
        this.toastr.clear();
        this.toastr.error(result.message, "Error", { timeOut: 5000 });
        localStorage.removeItem('userData');
        this._route.navigate(['/']);
      } else if (result.isSuccessful == false) {
        this.toastr.clear();
        this.toastr.error(result.message, "Error", { timeOut: 5000 });
      } else {
        this.toastr.clear();
        this.toastr.error("Failed to connect to the server", "Error", { timeOut: 5000 });
      }

    })
  }

  SearchCountryPort = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map(term => term.length < 1 ? []
        : this.portNameList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));


  GetPorts() {
    this.supplierServiceService.getCountryPortList()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.portList = result.data;
            let arrCountryPorts: Array<string> = [];
            result.data.forEach(element => {
              arrCountryPorts.push(element.countryPortName);
            });
            this.portNameList = arrCountryPorts;
          }
          else {
            this.toastr.clear();
            this.toastr.error("Fail to Load", "Error", { timeOut: 5000 })
          }
        }
        catch (Error) {
          alert(Error.message);
        }
      });
  }

  SelectCountryPort(event: any, portList, agentPortType: number) {
    if (agentPortType == 0) {
      for (let i = 0; i < portList.length; i++) {
        if (portList[i].countryPortName == event.item) {
          this.originName = portList[i].portName;
          this.originMasterId = portList[i].portMasterId;
          break;
        }
      }
    } else {
      for (let i = 0; i < portList.length; i++) {
        if (portList[i].countryPortName == event.item) {
          this.portName = portList[i].portName;
          this.portMasterId = portList[i].portMasterId;
          break;
        }
      }
    }
  }

  EditAgentContactDetails(contact: SupplierContactDetail) {
    this.editableRow = contact.SupplierContactDetailsID;
  }

  SaveAgentContactDetails() {
    this.editableRow = null;
  }

  GetPortsOriginList() {
    this.loading = true;
    this.supplierServiceService.getPortOriginList(this.SelectedCountry)
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.toastr.clear();
            this.toastr.success(result.message, "Success", { timeOut: 5000 })
            this.portList = result.data;
            let arrCountryPorts: Array<string> = [];
            result.data.forEach(element => {
              arrCountryPorts.push(element.countryPortName);
            });
            this.portNameList = arrCountryPorts;
            this.loading = false;
          }
          else {
            this.loading = false;
            this.toastr.clear();
            this.toastr.error(result.message, "Error", { timeOut: 5000 })
          }
        }
        catch (Error) {
          this.loading = false;
          alert(Error.message);
        }
      });
  }

  enableEdit() {
    if (this.modeFromQS == 'view') {
      this._route.navigate(['/manageAgent/'], { queryParams: { AgentId: this.AgentIDFromQS, mode: "edit" } });
    }
    else {
      this._route.navigate(['/manageAgent/'], { queryParams: { AgentId: this.AgentIDFromQS, mode: "view" } });
    }
  }

  GetDropdownValues() {
    this.bookingsService.GetDropdownDataForCreateBookingDetails().subscribe(resp => {
      if (resp.isSuccessful) {
        this.supplierList = resp.data._supplierList;
      }
    },
      error => {

      });
  }

  onChangeSupplier(supplier) {
    this.currentSupplierKey = supplier.currentTarget.value;
  }

  AddPorts(agentPortType: number) {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    if (this.SelectedCountry == undefined) {
      this.toastr.clear();
      this.toastr.error("Please select country before adding Port / Origin", "Error", { timeOut: 5000 });
    }
    else if (this.portMasterId == undefined && agentPortType == 1) {
      this.toastr.clear();
      this.toastr.error("Please Select Port Name", "Error", { timeOut: 5000 });
    } else if (this.originMasterId == undefined && agentPortType == 0) {
      this.toastr.clear();
      this.toastr.error("Please Select Origin Name", "Error", { timeOut: 5000 });
    } else {
      if (agentPortType == 0) {
        //For origin
        let isSelected = false;
        for (var i = 0; i < this.selectedPortsListForAgent.length; i++) {
          if (this.selectedPortsListForAgent[i].portName == this.originName && this.selectedPortsListForAgent[i].AgentPortType == 0) {
            this.toastr.clear();
            this.toastr.error("Origin name already selected", "Error", { timeOut: 5000 });
            isSelected = true;
            break;
          }
        }

        if (!isSelected) {
          this.selectedPortsListForAgent.push({
            AgentPortId: -(this.AgentPortList.length + 1),
            PortMasterId: this.originMasterId,
            AgentId: 0,
            AgentPortType: 0,
            isAgentPortIdActive: 1,
            portName: this.originName,
            AgentPortCreatedBy: this.userDetails.userId,
            AgentPortUpdatedBy: this.userDetails.userId,
            AgentPortCreatedDate: null,
            AgentPortUpdatedDate: null
          })
        }
        this.portList.countryOriginName = "";
      } else {
        let isSelected = false;
        for (var i = 0; i < this.selectedPortsListForAgent.length; i++) {
          if (this.selectedPortsListForAgent[i].portName == this.portName && this.selectedPortsListForAgent[i].AgentPortType == 1) {
            this.toastr.clear();
            this.toastr.error("Port name already selected", "Error", { timeOut: 5000 });
            isSelected = true;
            break;
          }
        }
        if (!isSelected) {
          this.selectedPortsListForAgent.push({
            AgentPortId: -(this.AgentPortList.length + 1),
            PortMasterId: this.portMasterId,
            AgentId: 0,
            AgentPortType: agentPortType,
            isAgentPortIdActive: 1,
            portName: this.portName,
            AgentPortCreatedBy: this.userDetails.userId,
            AgentPortUpdatedBy: this.userDetails.userId,
            AgentPortCreatedDate: null,
            AgentPortUpdatedDate: null
          })
        }
        this.portList.countryPortName = "";
      }
    }
  }

  RemovePorts(port: PortsForAgent) {
    if (this.modeFromQS == 'view') {
      this.toastr.clear();
      this.toastr.error("Cannot delete in view mode", "Error", { timeOut: 5000 })
    }
    if (port.AgentPortId > 0) {
      this.loading = true;
      this.agentService.DeleteAgentPort(port.AgentPortId).subscribe(result => {
        if (result.isSuccessful == true) {
          this.loading = false;
          this.toastr.clear();
          this.toastr.success(result.message, "Success", { timeOut: 5000 });
          for (var j = 0; j < this.selectedPortsListForAgent.length; j++) {
            if (port.PortMasterId == this.selectedPortsListForAgent[j].PortMasterId) {
              this.selectedPortsListForAgent.splice(j, 1);
              break;
            }
          }
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        } else if (result.isSuccessful == false) {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
        } else {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error("Failed to connect to the server", "Error", { timeOut: 5000 });
        }
      })
    } else {
      for (var j = 0; j < this.selectedPortsListForAgent.length; j++) {
        if (port.PortMasterId == this.selectedPortsListForAgent[j].PortMasterId) {
          this.selectedPortsListForAgent.splice(j, 1);
          break;
        }
      }
    }
  }

  AssignSupplier() {
    if (this.currentSupplierKey == null) {
      this.toastr.warning("Select supplier to assign.");
      return;
    }
    if (this.selectedSuppliersListForAgent.some(e => e.Key == this.currentSupplierKey)) {
      this.toastr.warning("This supplier is already assigned, try another one.");
    }
    else {
      let supplierToAssign = this.supplierList.filter(obj => { return obj.key == this.currentSupplierKey });
      this.selectedSuppliersListForAgent.push({
        Name: supplierToAssign[0].value,
        Key: this.currentSupplierKey
      });
    }
  }

  RemoveSupplier(supplierKeyToRemove: any) {
    if (this.modeFromQS == 'view') {
      this.toastr.clear();
      this.toastr.error("Cannot delete in view mode", "Error", { timeOut: 5000 });
      return;
    }
    if (this.modeFromQS == 'edit') {
      this.agentService.DeleteAgentsSupplier(supplierKeyToRemove, this.AgentIDFromQS).subscribe(resp => {
        this.toastr.clear();
        if (resp.isSuccessful) {
          this.toastr.success(resp.message, "Success", { timeOut: 5000 });
        }
        else {
          this.toastr.error(resp.message, "Error", { timeOut: 5000 });
        }
      },
        error => {
          console.log(error);
        });
    }
    let index = this.selectedSuppliersListForAgent.findIndex((obj: { Key: any; Value: any }) => obj.Key == supplierKeyToRemove);
    this.selectedSuppliersListForAgent.splice(index, 1);
    //
  }
}
