import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { Global } from 'src/app/shared/global';
import { PortMasterDetails } from './PortMaster';

@Injectable({
  providedIn: 'root'
})
export class OriginDestinationPortService {
  //userDetails = JSON.parse(localStorage.getItem('userData'));
  public userDetails: any;
  _global:Global;
  constructor(private http: Http,private global: Global) { 
    this._global = global;
  }

  //Added by Mahesh on 13-11-2019
  //To get country list
  public getCountryList(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "MasterData/GetCountryList", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Mahesh on 13-11-2019
  //Port master will be filtered
  //To get country list
  public getAllPortMasterList(searchParams:PortMasterDetails): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    let _searchParams = JSON.stringify(searchParams);
    return this.http.get("" + this._global.apiBaseURl + "MasterData/GetAllPostMasterList?SearchParams="+_searchParams+"", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Mahesh on 14-11-2019
  //To add port master details
  public addPortMasterDetails(portMasterList): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.post("" + this._global.apiBaseURl + "MasterData/InsertOrUpdatePortMaster",portMasterList, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Mahesh on 14-11-2019
  //To delete port master
  public deletePortMasterDetails(portMasterId,loggedInUserId): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "MasterData/DeletePostMaster?PortMasterId="+portMasterId+"&LoggedInUserId="+loggedInUserId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

    //added by Omkar 17th Sept 2020
  //to Delete Destination permantely 
  public deletePortOrigin(PortMasterId: any): Promise<any> {
   
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.post("" + this._global.apiBaseURl + "MasterData/DeletePortMaster", PortMasterId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)

  }
}
