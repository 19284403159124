import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from '../shared/global';
import { CreateBookingsModel } from './CreateBooking.model';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {
  private _global: Global;
  constructor(private httpCli: HttpClient, global: Global) {
    this._global = global;
  }

  public GetDataForBookingDetailsSearch() {
    let userDetails = JSON.parse(localStorage.getItem('userData'));
    let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + userDetails.accessToken)
      .set('UserID', userDetails.userId);
    return this.httpCli.get<any>("" + this._global.apiBaseURl + "FetchData/GetDataForBookingDetailsSearch?UserID=" + userDetails.userId + "",
      { headers: requestheaders });
  }

  public GetBookingsSearchData(searchParameters: any) {
    let userDetails = JSON.parse(localStorage.getItem('userData'));
    let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + userDetails.accessToken)
      .set('UserID', userDetails.userId);
    let searchParam = JSON.stringify(searchParameters);
    return this.httpCli.get<any>("" + this._global.apiBaseURl + "FetchData/GetBookingsSearchData?searchParam=" + searchParam, { headers: requestheaders });
  }

  public GetDropdownDataForCreateBookingDetails() {
    let userDetails = JSON.parse(localStorage.getItem('userData'));
    let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + userDetails.accessToken)
      .set('UserID', userDetails.userId);
    return this.httpCli.get<any>("" + this._global.apiBaseURl + "FetchData/GetDataForCreateBookingDetails?UserID=" + userDetails.userId + "",
      { headers: requestheaders });
  }

  public PostBookingDetails(bookingModel: CreateBookingsModel) {
    let userDetails = JSON.parse(localStorage.getItem('userData'));
    let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + userDetails.accessToken)
      .set('UserID', userDetails.userId);
    bookingModel.CurrentUserId = userDetails.userId;
    return this.httpCli.post<any>("" + this._global.apiBaseURl + "FetchData/PostBookingDetails", bookingModel, { headers: requestheaders });
  }

  public UpdateBookingDetails(updatedBookingModel: CreateBookingsModel) {
    let userDetails = JSON.parse(localStorage.getItem('userData'));
    let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + userDetails.accessToken)
      .set('UserID', userDetails.userId);
    updatedBookingModel.CurrentUserId = userDetails.userId;
    return this.httpCli.post<any>("" + this._global.apiBaseURl + "FetchData/UpdateBookingDetails", updatedBookingModel,
      { headers: requestheaders });
  }

  public GetBookingsDetailsById(bookingId: number) {
    let userDetails = JSON.parse(localStorage.getItem('userData'));
    let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + userDetails.accessToken)
      .set('UserID', userDetails.userId);
    return this.httpCli.get<any>("" + this._global.apiBaseURl + "FetchData/GetBookingDetailsById?BookingID=" + bookingId + "",
      { headers: requestheaders });
  }

  public GetReplicateBookingsDetailsById(bookingId: number) {
    let userDetails = JSON.parse(localStorage.getItem('userData'));
    let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + userDetails.accessToken)
      .set('UserID', userDetails.userId);
    return this.httpCli.get<any>("" + this._global.apiBaseURl + "FetchData/GetReplicateBookingsDetailsById?BookingID=" + bookingId + "",
      { headers: requestheaders });
  }
}
