export class Menu {
  MenuName: string;
  MenuControllName: string;
  MenuLinkName: string;
  MenuAcctive: boolean;
  MenuChilds: MenuChilds[]
}


export class MenuChilds {
  MenuName: string;
  MenuControllName: string;
  MenuLinkName: string;
  SubMenuAcctive: boolean;
}

export class ProfileMenu {
  MenuLinkText: string;
  MenuLinkController_name: string;
  MenuLinkPageName: string;
}
