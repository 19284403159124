import { Component, ViewChild, OnInit, AfterViewInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User, ForgotPasswordEmail, ValidateResetPasswordDetail, ResetPassword, UpdatePassword, ContactUs } from './user';//added by vivek Redekar
import { LoginService } from './shared/login.service'; //added by vivek Redekar
import { ToastrService } from 'ngx-toastr'; //added by vivek Redekar
import { NgForm } from '@angular/forms';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { Global } from '../shared/global';
declare var require: any;
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  version: string = require('../../../../ClientApp/package.json').version;
  public user: User = null;
  public forgotPasswordEmail: ForgotPasswordEmail = null;
  public validateResetPasswordDetail: ValidateResetPasswordDetail = null;
  public resetPassword: ResetPassword = null;
  public updatePassword: UpdatePassword = null;
  public contactUs: ContactUs = null;
  public signinDiv: boolean = true;
  public forgotPasswordDiv: boolean = false;
  public forgotPasswordResponseDiv: boolean = false;
  public resetPasswordDiv: boolean = false;
  btnOutline: boolean = true; //hide show password

  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading: boolean = false;

  public userDetails: any;
  public requestID: number;
  public isDisabled: boolean = false;
  public passwordOperation = "Password Recovery";
  //private id: any;
  constructor(private _route: Router, private route: ActivatedRoute, private _loginService: LoginService, private toastr: ToastrService, private global: Global) {
    this.user = new User();
    this.forgotPasswordEmail = new ForgotPasswordEmail();
    this.validateResetPasswordDetail = new ValidateResetPasswordDetail();
    this.resetPassword = new ResetPassword();
    this.updatePassword = new UpdatePassword();
    this.contactUs = new ContactUs();
  }

  ngAfterViewInit() { }
  ngOnInit() {

    //let userDetaild = JSON.parse(sessionStorage.getItem('userData'));
    if (this._route.url.indexOf('login') >= 0) {
      localStorage.removeItem('userData');
    }

    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    if (this.userDetails != null && this.userDetails.userStatusCfgId == 2) {

      // if the user is customer then go to customer dashbaord
      if (this.userDetails.userEntityIdentifier == 2) {
        this._route.navigate(['/dashboard']);
      }
      else {
        this._route.navigate(['/Home/Dashboard']);
      }
    } else if (this.userDetails != null && this.userDetails.userStatusCfgId == 1) {
      this._route.navigate(['/login']);
    }
    //localStorage.removeItem('userData');
    this.route.queryParams.subscribe(params => {
      this.requestID = params['ResetInfoID']
    });
    if (this.requestID != undefined) {
      //get email of the requested ID.
      this.GetEmailID(this.requestID);
    }
    $(document).ready(() => {
      //var ftrHt = $('footer').innerHeight();

      //$('#scrollable-area').height($(window).height() - ftrHt);
      //$('#scrollable-area').css('top', 0);

    });

  }
  checkValidCredentials() {

    this.loading = true;
    if (this.user.userEmailId != null && this.user.userPassword != null) {
      this.route.queryParams.subscribe(params => {
        this.requestID = params['ResetInfoID']
      });
      if (this.requestID > 0) {
        //If parameter exist use api to get detailed email to reset password.
        this.validateResetPasswordDetail.resteID = this.requestID;
        this.validateResetPasswordDetail.userEmail = this.user.userEmailId;
        this.validateResetPasswordDetail.systemPassword = this.user.userPassword;
        this._loginService.validateResetPasswordDetaild(this.validateResetPasswordDetail)
          .then((result) => {
            if (result.isSuccessful == true) {
              this.loading = false;
              this.signinDiv = false;
              this.forgotPasswordDiv = false;
              this.forgotPasswordResponseDiv = false;
              this.resetPasswordDiv = true;
            }
            else {
              this.toastr.error(result.message, "Error", { timeOut: 5000 });
              this.loading = false;
            }
          })
          .catch((error) => {
            console.log(error);
          })
      } else {
        //If parameter not exist use simple login;
        this._loginService.login(this.user)
          .then((result) => {
            if (result.isSuccessful == true) {
              this.loading = false;
              this.userDetails = {
                // as we will return the date format for the customer this is chaged 
                userFirstName: result.data.user.userFirstName,
                userMasterLastName: result.data.user.userMasterLastName,
                roleMasterId: result.data.user.roleMasterId,
                userId: result.data.user.userId,
                accessToken: result.token,
                dateFormat: result.data.dateFormat,
                userEntityIdentifier: result.data.user.userEntityIdentifier,
                supplierName: result.data.user.supplierdispalyname,
                userStatusCfgId: result.data.user.userStatusCfgId,
                userEmail: this.user.userEmailId,
                menuAction: result.data.user.menuActions
              }
              //this.user.userEmailId = '';
              //this.user.userPassword = '';

              //set the dateformat in the global variables
              this.global.dateFormat = result.data.dateFormat;
              localStorage.setItem('userData', JSON.stringify(this.userDetails));

              if (result.data.user.userStatusCfgId == 1) {
                this.loading = false;
                this.signinDiv = false;
                this.forgotPasswordDiv = false;
                this.forgotPasswordResponseDiv = false;
                this.resetPasswordDiv = true;
                this.passwordOperation = "Set New Password";
              } else {
                this.loading = false;
                this.toastr.success(result.message, "", { timeOut: 3000 });
                //this._route.navigate(['/dashboard']);
                //Redirection is now done here instated of on dashboard load.
                // as per issue #6 from sheet 23-08-2020 by omkar on 23rd Aug 2020
                var OSPO_Id = -1;
                this.route.queryParams.subscribe(data => {
                  if (data["poid"] != undefined) {
                    OSPO_Id = data["poid"];
                  }
                });
                if (result.data.user.userEntityIdentifier == 3) {
                  //Supplier
                  if (OSPO_Id != -1) {
                    //redirect to PO 
                    //this._route.navigate(['/PoUpload/PoItemDetails?poid='+ OSPO_Id]);
                    this._route.navigate(['PoUpload/PoItemDetails'], { queryParams: { poid: OSPO_Id } });
                  } else {
                    this._route.navigate(['/Home/Dashboard']);
                  }
                }
                else if (result.data.user.userEntityIdentifier == 2 || result.data.user.userEntityIdentifier == 1) {
                  //Customer
                  if (OSPO_Id != -1) {
                    //redirect to PO  PoUpload/PoAddEntry?poid=43
                    this._route.navigate(['PoUpload/PoAddEntry'], { queryParams: { poid: OSPO_Id } });
                  } else {
                    this._route.navigate(['/dashboard']);
                  }
                }
                else if (result.data.user.userEntityIdentifier == 4) {
                  //agent
                  this._route.navigate(['/Bookings/SearchBookings']);
                }
              }
            }
            else if (result.isSuccessful == false) {
              this.toastr.error(result.message, "Error", { timeOut: 3000 });
              this.loading = false;
            }
            else {

              this.toastr.error("Failed to connect to the server", "Error", { timeOut: 5000 });
              this.loading = false;
            }
          }).catch((result) => {
            this.toastr.error("Failed to connect to the server", "Error", { timeOut: 5000 });
            this.loading = false;
            console.log(result);
          })

      }
    }
    else {
      this.loading = false;
    }

  }

  clearFields(forms: NgForm) {

    forms.controls['email'].reset();
    forms.controls['password'].reset();

  }
  signinDivHide() {
    this.signinDiv = false;
    this.forgotPasswordDiv = true;
  }

  visibleLoginPage() {
    this.signinDiv = true;
    this.forgotPasswordDiv = false;
  }
  //Function to get recover password detail email.
  forgotPasswordDivHide() {

    this.loading = true;
    if (this.forgotPasswordEmail.userEmail != null) {
      // windows.location.href adds the querystring again, so changed to origin Shailesh
      this.forgotPasswordEmail.baseURLToAddInEmail = window.location.origin + "/";
      this._loginService.getResetPasswordDetailEmail(this.forgotPasswordEmail)
        .then((result) => {
          debugger
          if (result.isSuccessful == true) {
            this.toastr.success(result.message, "Success", { timeOut: 3000 });
          }
          else {
            if (result.data == "Account Inactive") {
              this.toastr.warning(result.message, "Warning", { timeOut: 3000 });
            }
            else {
              this.toastr.success(result.message, "Success", { timeOut: 3000 });
            }
          }
          this.signinDiv = true;
          this.forgotPasswordDiv = false;
          this.forgotPasswordResponseDiv = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });

    }
    else {
      this.loading = false;
    }
  }

  resetOldPassword() {
    this.loading = true;
    if (this.resetPassword.newPassword != null && this.resetPassword.reEnterPassword != null || this.resetPassword.resteID) {
      //var userDetails = JSON.parse(sessionStorage.getItem('userData')); 
      if (this.userDetails != null && this.userDetails.userId > 0) {
        this.updatePassword.newPassword = this.resetPassword.newPassword;
        this.updatePassword.userId = this.userDetails.userId;
        this._loginService.updateIntrnalUserPassword(this.updatePassword).then((result) => {
          if (result.isSuccessful == true) {
            this.toastr.success(result.message, "", { timeOut: 3000 });
            this.loading = false;
            //Added by Mahesh on 28-04-2020
            this.userDetails = {
              // as we will return the date format for the customer this is chaged 
              userFirstName: result.data.user.userFirstName,
              userMasterLastName: result.data.user.userMasterLastName,
              roleMasterId: result.data.user.roleMasterId,
              userId: result.data.user.userId,
              accessToken: result.token,
              dateFormat: result.data.dateFormat,
              userEntityIdentifier: result.data.user.userEntityIdentifier,
              supplierName: result.data.user.supplierdispalyname,
              userStatusCfgId: result.data.user.userStatusCfgId
            }

            this.global.dateFormat = result.data.dateFormat;
            localStorage.setItem('userData', JSON.stringify(this.userDetails));
            //End 28-04-2020

            //if it is customer then dashbaord else supplier
            if (result.data.user.userEntityIdentifier == 2) {
              this._route.navigate(['/dashboard']);
            }
            else {
              this._route.navigate(['/Home/Dashboard']);
            }

          }
          else {
            this.loading = false;
            this.toastr.error(result.message, "", { timeOut: 3000 });
          }
        })
          .catch((error) => {
            console.log(error);
          })
      }
      else {
        this.route.queryParams.subscribe(params => {
          this.requestID = params['ResetInfoID']
        });
        this.resetPassword.resteID = this.requestID;
        //To reset password.
        this._loginService.resetPassword(this.resetPassword)
          .then((result) => {
            if (result.isSuccessful == true) {
              //To get user details for creting session variable when user resets his password. 
              this._loginService.getUserData(this.resetPassword)
                .then((result) => {
                  if (result.isSuccessful == true) {
                    this.loading = false;
                    this.userDetails = {
                      userFirstName: result.data.userFirstName,
                      userMasterLastName: result.data.userMasterLastName,
                      roleMasterId: result.data.roleMasterId,
                      userId: result.data.userId,
                      accessToken: result.token,
                      dateFormat: result.data.dateFormat,
                      userEntityIdentifier: result.data.userEntityIdentifier
                    }
                    localStorage.setItem('userData', JSON.stringify(this.userDetails));

                    //if it is customer then dashbaord else supplier
                    if (result.data.userEntityIdentifier == 2) {
                      this._route.navigate(['/dashboard']);
                    }
                    else {
                      this._route.navigate(['/Home/Dashboard']);
                    }



                  }
                  else if (result.isSuccessful == false) {
                    this.loading = false;
                    this.toastr.error(result.message, "", { timeOut: 5000 });
                    this._route.navigate(['/']);
                  }
                })
              this.loading = false;
              this.toastr.success(result.message, "", { timeOut: 3000 });
              //this._route.navigate(['/dashboard']);
            }
            else {
              this.loading = false;
              this.toastr.error(result.message, "", { timeOut: 3000 });
            }
          })
          .catch((error) => {
            console.log(error);
          })
      }

    }
    else {
      this.loading = false;
    }
  }

  //Added by Mahesh on 09-10-2019 to show hide password field
  statusForPasswordEye: boolean = false;
  inputTypeForEnterPassword: string = 'password';
  clickEventForShowHidePassword() {
    this.statusForPasswordEye = !this.statusForPasswordEye;
    if (this.statusForPasswordEye == false) {
      this.inputTypeForEnterPassword = 'password';
    } else {
      this.inputTypeForEnterPassword = 'text';
    }
  }

  //Added by Mahesh on 09-10-2019 to show hide password field
  statusForResetPasswordEye: boolean = false;
  inputTypeForEnterNewPassword: string = 'password';
  clickEventForShowHideNewPassword() {
    this.statusForResetPasswordEye = !this.statusForResetPasswordEye;
    if (this.statusForResetPasswordEye == false) {
      this.inputTypeForEnterNewPassword = 'password';
    } else {
      this.inputTypeForEnterNewPassword = 'text';
    }
  }

  //Added by Mahesh on 09-10-2019 to show hide re-password field
  statusForRePasswordEye: boolean = false;
  inputTypeForReEnterPassword: string = 'password';
  clickEventForReEnterPassword() {
    this.statusForRePasswordEye = !this.statusForRePasswordEye;
    if (this.statusForRePasswordEye == false) {
      this.inputTypeForReEnterPassword = 'password';
    } else {
      this.inputTypeForReEnterPassword = 'text';
    }
  }


  //Added by Varsha on 16-10-2019
  //To Add Contact Us in t_contact_us table.
  public InsertContactUs() {
    this.loading = true;
    //Added by Mahesh on 02-04-2020
    console.log(window.location.href.indexOf('login') > 0);
    if (window.location.href.indexOf('login') > 0) {
      this.contactUs.isMailFromLoginPage = true;
    } else {
      this.contactUs.isMailFromLoginPage = false;
    }
    console.log(this.contactUs.isMailFromLoginPage);
    //End 02-04-2020

    this._loginService.InsertContactUs(this.contactUs)
      .then((result) => {
        try {
          if (result.isSuccessful == true) {

            this.toastr.success(result.message, "", { timeOut: 5000 });
            this.loading = false;
            window.location.reload();
          }
          else if (result.isSuccessful == false) {
            this.loading = false;
            this.toastr.error(result.message, "", { timeOut: 5000 });
          }
          else {
            this.loading = false;
            this.toastr.error("Failed to connect to the server", "", { timeOut: 5000 });
          }
        } catch (Error) {
          console.log(Error);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }


  //get email id After Reset password
  public GetEmailID(requestID: any) {

    this._loginService.getEmailIdAfterResetPassword(requestID)
      .then((result) => {
        if (result.isSuccessful == true) {
          this.user.userEmailId = result.data;
          this.isDisabled = true;
        }
        else {
          this.isDisabled = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


}
