import { Injectable } from '@angular/core';
import { Global } from '../../shared/global';
import { Headers, Http, Response } from '@angular/http';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashBoardSrvService {
  public userDetails: any;
  private _global: Global;
  constructor(private global: Global, private http: Http, private httpcli: HttpClient) {
    this._global = global;
  }

  public serviceGetTheCustomerDashBoardData() {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.userDetails.accessToken)
      .set('UserID', this.userDetails.userId);
    // let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    //("" + this._global.apiBaseURl + "DashBoard/GetDataForCustomerDashBoard", { headers: headers })
    return this.httpcli.get<any>("" + this._global.apiBaseURl + "DashBoard/GetDataForCustomerDashBoard?UserID=" + this.userDetails.userId + "",
      { headers: requestheaders });
  }

  //Added by varsha on 23-2-2020
  //To get User Entity Id.
  public getUserEntityId(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserId': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "DashBoard/GetUserEntityId?UserId=" + this.userDetails.userId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  public VerifyOtherDetails(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserId': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "user/VerifyCustomerOtherInfoForPO", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  public GetSupplierDelayedPos(UserID): Observable<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let requestheaders = new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.userDetails.accessToken)
      .set('UserID', this.userDetails.userId);

    return this.httpcli.get<any>("" + this._global.apiBaseURl + "DashBoard/GetSupplierDelayedPos?UserID=" + this.userDetails.userId + "",
      { headers: requestheaders });
  }

} 
