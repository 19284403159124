import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { Global } from '../../../../shared/global';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  _global: Global;
  //public userDetails = JSON.parse(localStorage.getItem('userData'));
  public userDetails: any;
  constructor(private global: Global, private http: Http) {
    this._global = global;
  }

  public serviceGetMenusByUserRole(roleID: number) {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "FetchData/GetMenusByRoleId?RoleID=" + roleID + "", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(response => response.json() as any);
  }

  public getCustomerCompanyLogo(userId): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });

    // the userid is hardcoded to 1 to get the company logo
    // As discussed on 24-10-2019
    userId = 1;
    return this.http.get("" + this._global.apiBaseURl + "Customer/GetLogoImage?userId=" + userId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  public getUserProfileImage(userId): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Customer/GetUserProfileImage?userId=" + userId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //Added by Varsha on 06-09-2019
  //To Update Customer Details By Login UserId
  userProfileImageUpload(profilePhotosDetails: any): Promise<any> {
    try {
      this.userDetails = JSON.parse(localStorage.getItem('userData'));
      let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
      return this.http.post("" + this._global.apiBaseURl + "Customer/InsertUserProfileImage", profilePhotosDetails, { headers: headers })
        .toPromise()
        .then(response => response.json() as any)
        .catch(respose => respose.json() as any)
    } catch (e) {
      console.debug(e);
    }
  }

  public getInternalUserDetailsForEdit(userId): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "User/GetInternalUserDetailsForEdit?UserId=" + userId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  public getUserRole(userId): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "User/GetRoleMaster?userId=" + userId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  public getUserStatus(): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "User/GetUserStatus", { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  public login(user: any): Promise<any> {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post("" + this._global.apiBaseURl + "authentication/login", user, { headers: headers })
      .toPromise()
      .then(
        response => {
          let userData = response.json() as any;
          return (userData);
        }
      )
      .catch(response => {
        console.log(response);
        response.json() as any;
      });
  }

  public ResetPassword(resetPwModel): Promise<any> {
    //this.userDetails = JSON.parse(localStorage.getItem('userData'));
    //let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.post("" + this._global.apiBaseURl + "user/ResetPassword", resetPwModel)
      .toPromise()
      .then(
        response => {
          let userData = response.json() as any;
          return (userData);
        }
      )
      .catch(response => {
        console.log(response);
        response.json() as any;
      });
  }

  //added by kunal on 04-04-2021
  public getPoNotification(userId): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Supplier/GetPoNotification?userId=" + userId, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  public updateNotification(notificationfileName): Promise<any> {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'bearer ' + this.userDetails.accessToken, 'UserID': this.userDetails.userId });
    return this.http.get("" + this._global.apiBaseURl + "Supplier/UpdatePoNotification?NotificationFileName=" + notificationfileName, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }
}
