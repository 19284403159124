import { Component, OnInit, PACKAGE_ROOT_URL, ɵConsole } from '@angular/core';
import { Location } from '@angular/common';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SupplierServiceService } from './supplier-service.service';
import { Supplier, SupplierContactDetail, IncoTerms, PortsForSupplier } from './supplierForInsertion';
import { NgModel } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../../../app/shared/delete-confirmation/confirmation-dialog.service';
import { FormlabelsService } from '../../shared/formlabels.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { OriginDestinationPortService } from '../../master/origin-destination-ports/shared/origin-destination-port.service';
import { Router } from '@angular/router';
import { SupplierPortModel } from './supplierModel';
import { Observable, of } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/filter';
//import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.css'],

})
export class SupplierComponent implements OnInit {
  supplierIDFromQS: number = 0;
  supplierDetails: any = {};
  public contactNameForAdd: string;
  public contactEmailForAdd: string;
  public pageLabels: any[];
  public pageMessages: any[];
  public disableSuplierEmailFeild: boolean = true;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading: boolean = false;
  public countryList: any;
  //public userDetails = JSON.parse(localStorage.getItem('userData'));
  public userDetails: any;
  public portNameList: any = [];
  public portList: any = [];
  public supplierDetailsPortList: any = [];
  public supplierPortList: SupplierPortModel[] = [];
  public addPortList: any[] = [];
  public portName: any = null;
  public portMasterId: any = null;
  public disablePortList: boolean;
  public SelectedCountry: any;
  //model: any;
  //searching = false;
  //searchFailed = false;

  public selectedPortsListForSupplier: PortsForSupplier[] = [];//Added by Mahesh on 07-04-2020
  public originName: any = null;//Added by Mahesh on 07-04-2020
  public originMasterId: any = null;//Added by Mahesh on 07-04-2020
  public isDomesticTextBoxDisabled: boolean = true;//Added by Mahesh on 09-04-2020
  public isOverseasTextBoxDisabled: boolean = true;//Added by Mahesh on 09-04-2020
  public editableRow: any;//Added by Mahesh on 04-05-2020
  showSavebtn: boolean = false;

  public isDomesticTextCheck: boolean = false;
  public isOverseasTextCheck: boolean = false;

  modeFromQS: string;
  editViewText: string;
  constructor(private _location: Location, private route: ActivatedRoute, private supplierServiceService: SupplierServiceService,
    private toastr: ToastrService, private toastrService: ToastrService, private confirmationDialogService: ConfirmationDialogService, private formLabelService: FormlabelsService
    , private _OriginDestinationPortService: OriginDestinationPortService, private _route: Router) {

    //this.supplierDetails = new Supplier();

    this.supplierDetails.SupplierContactDetails = new Array<SupplierContactDetail>();
    this.supplierDetails.SupplierIncoTerms = new Array<IncoTerms>();
    this.supplierDetails.SupplierPortsList = new Array<PortsForSupplier>();
    this.supplierIDFromQS = 0;

    this.route.queryParams.subscribe((data) => {
      this.supplierIDFromQS = data["supplierid"];
      this.modeFromQS = data["mode"];
      this.editViewText = data["mode"] == 'view' ? 'Edit' : 'View';
    });
    this.contactNameForAdd = "";
  }

  ngOnInit() {
    // this.supplierServiceService.serviceFillControls()
    //   .subscribe((data) => {
    //
    //     if (data.isSuccessful) {

    //       this.supplierDetails.SupplierIncoTerms = new Array<IncoTerms>();
    //       data.data.incoTerms.forEach(element => {
    //         this.supplierDetails.SupplierIncoTerms.push({
    //           key: element.key,
    //           value: element.value
    //         });
    //       });
    //     }

    //   })
    this.loading = true;
    this.getAllLabelsAndMessages();

    this.getCoutryList();
    //this.getPorts();
    this.loading = false;//Added by Mahesh on 08-03-2020, To close the loader
    this.loadFormData();
    this.getsupplierid();
  }

  loadFormData() {
    this.loading = true
    this.fillTheFormControls()
      .then((data) => {

        if (data.isSuccessful) {
          this.loading = false
          data.data.incoTerms.forEach(element => {
            this.supplierDetails.SupplierIncoTerms.push({
              key: element.key,
              value: element.value
            });
          });
        }
        if (this.supplierIDFromQS) {

          this.getTheSupplierDetails(this.supplierIDFromQS);
        }
      })
      .catch((error) => {

      })
  }

  btnGoBack() {
    this._location.back();
  }

  getTheSupplierDetails(supplierID: number) {
    this.disablePortList = true;
    this.supplierServiceService.serviceGetSupplierDetailsBySupplierID(supplierID).subscribe((response) => {
      console.log(response);
      if (response.isSuccessful) {
        this.loading = false;
        this.toastr.success(this.pageLabels[710], "Success");//Supplier Details Loaded Successfully![710]

        var data = response.data;
        console.log(" Supplier Data", data);
        this.supplierDetails = new Supplier();
        this.supplierDetails.SupplierContactDetails = new Array<SupplierContactDetail>();
        this.supplierDetails.SupplierIncoTerms = new Array<IncoTerms>();

        this.supplierDetails.CustomerId = data.customerId;
        this.supplierDetails.SupplierBuyerEmailid = data.supplierBuyerEmailid;
        this.supplierDetails.SupplierBuyerName = data.supplierBuyerName;
        this.supplierDetails.SupplierBuyerContactNo = data.supplierBuyerContactNo;
        this.supplierDetails.SupplierCity = data.supplierCity;
        this.supplierDetails.supplierCountryCode = data.supplierCountryCode;

        this.supplierDetails.SupplierDisplayName = data.supplierDisplayName;
        this.supplierDetails.SupplierErpVendorId = data.supplierErpVendorId;
        this.supplierDetails.SupplierId = data.supplierId;
        this.supplierDetails.SupplierIsActive = data.supplierIsActive;
        this.supplierDetails.SupplierName = data.supplierName;
        this.supplierDetails.SupplierPlannerEmailid = data.supplierPlannerEmailid;
        this.supplierDetails.SupplierPlannerName = data.supplierPlannerName;
        this.supplierDetails.SupplierPlannerContactNo = data.supplierPlannerContactNo;
        this.supplierDetails.SupplierPrimaryContactEmail = data.supplierPrimaryContactEmail;
        this.supplierDetails.SupplierPrimaryContactName = data.supplierPrimaryContactName;
        this.supplierDetails.SupplierPrimaryContactNumber = data.supplierPrimaryContactNumber;
        this.supplierDetails.SupplierState = data.supplierState;
        this.supplierDetails.SupplierStreetName = data.supplierStreetName;
        this.supplierDetails.supplierTypeDomestic = data.supplierTypeDomestic;
        this.supplierDetails.supplierTypeOverseas = data.supplierTypeOverseas;

        this.supplierDetails.SupplierWebSiteUrl = data.supplierWebSiteUrl;
        this.supplierDetails.SupplierZipcode = data.supplierZipcode;
        this.supplierDetails.SupplierIncoterrmId = data.supplierIncoterrmId;
        this.supplierDetails.SupplierStatus = data.supplierStatus;
        data.supplierContactDetails.forEach(element => {
          this.supplierDetails.SupplierContactDetails.push({
            SupplierContactDetailsID: element.supplierContactDetailsID,
            SupplierContactName: element.supplierContactName,
            SupplierContactEmailid: element.supplierContactEmailid
          });
        });

        data.incoTerms.forEach(element => {
          this.supplierDetails.SupplierIncoTerms.push({
            key: element.key,
            value: element.value
          });
        });

        this.supplierDetails.SupplierPorts = [];
        this.supplierDetails.SupplierPorts = data.supplierPorts;

        //this.getSupplierPorts(data.supplierCountryCode);
        this.supplierDetailsPortList = data.supplierPorts;

        for (let i = 0; i < this.portList.length; i++) {
          if (this.portList[i].isSupplierPortIdActive == true) {
            this.portList[i].status = "checked";
          }
        }

        //Added by Mahesh on 07-04-2020, To get the selected ports list of a supplier
        //this.supplierDetails.SupplierPortsList = new Array<PortsForSupplier>();
        this.selectedPortsListForSupplier = data.supplierPorts;
        this.isDomesticTextBoxDisabled = data.supplierTypeDomestic == true ? false : true;
        this.isOverseasTextBoxDisabled = data.supplierTypeOverseas == true ? false : true;
        //End 07-04-2020
      }
    },
      (error) => {
        this.loading = false
        console.log(error);
        this.toastr.error(error.message, error.name);
      }
    );
    this.disableSuplierEmailFeild = false;
  }

  ValidEntry(): Promise<any> {

    return new Promise((data) => {
      let enteredEmailAddress: string = this.contactEmailForAdd;
      this.supplierDetails.SupplierContactDetails.forEach((element) => {
        if (element.SupplierContactEmailid == enteredEmailAddress) {
          data(false);
          return false;
        }
      });
      data(true);
    });
  }

  addContactDetails() {


    this, this.ValidEntry()
      .then((data) => {
        if (data) {
          let newSupplierContactDetailsID = -(this.supplierDetails.SupplierContactDetails.length + 1);
          this.supplierDetails.SupplierContactDetails.push({
            SupplierContactDetailsID: newSupplierContactDetailsID,
            SupplierContactName: this.contactNameForAdd,
            SupplierContactEmailid: this.contactEmailForAdd
          });
          this.contactNameForAdd = '';
          this.contactEmailForAdd = '';
        }
        else {
          this.toastr.error(this.pageLabels[135]); /*'The entered email address is already used'*/
        }
      })
      .catch((error) => {
        this.toastr.error(this.pageLabels[711], this.pageLabels[712]);//Something went wrong[711],Add contact details[712]
      })
  }

  POSTUpdateSupplier() {
    this.loading = true;
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.supplierDetails.SupplierCreatedBy = this.userDetails.userId;
    this.supplierDetails.SupplierUpdatedBy = this.userDetails.userId;
    this.supplierDetails.BaseURLToAddInEmail = window.location.protocol + "//" + window.location.host;
    this.supplierDetails.SupplierPorts = this.supplierPortList;
    // this.supplierDetails.SupplierPorts = this.addPortList;

    if (this.supplierDetails.supplierTypeDomestic == false && this.supplierDetails.supplierTypeOverseas == false) {
      this.toastr.clear();
      this.toastr.error(this.pageLabels[713], "Error", { timeOut: 5000 });//Please select at least one supplier type[713]
      this.loading = false;
      return;
    }

    //Added by Mahesh on 08-04-2020
    if (this.supplierDetails.supplierTypeDomestic == true) {
      let count = this.selectedPortsListForSupplier.filter(x => x.supplierPortType == false).length;
      if (count == 0) {
        this.toastr.clear();
        this.toastr.error(this.pageLabels[714], "Error", { timeOut: 5000 });//Please select at least one origin[714]
        this.loading = false;
        return;
      }
    }

    if (this.supplierDetails.supplierTypeOverseas == true) {
      let count = this.selectedPortsListForSupplier.filter(x => x.supplierPortType == false).length;
      if (count == 0) {
        this.toastr.clear();
        this.toastr.error(this.pageLabels[715], "Error", { timeOut: 5000 });//Please select at least one port[715]
        this.loading = false;
        return;
      }
    }
    //End 08-04-2020

    this.supplierDetails.SupplierPorts = this.selectedPortsListForSupplier;//Added by mahesh on 07-04-2020

    //if (this.supplierDetails.supplierTypeOverseas != undefined || this.supplierDetails.supplierTypeDomestic != undefined) {
    //  for (let i = 0; i < this.addPortList.length; i++) {
    //    this.supplierDetails.SupplierPorts.push({
    //      portName: this.addPortList[i].portName,
    //      portMasterId: this.addPortList[i].portMasterId
    //    });
    //  }
    //}

    if (this.supplierDetails.SupplierIncoterrmId == undefined) {
      this.toastr.clear();
      this.toastr.error(this.pageLabels[716], "Error", { timeOut: 5000 });//Incoterm is mandatory[716]
      this.loading = false;
    }
    else if (this.supplierDetails.supplierCountryCode == undefined) {
      this.toastr.clear();
      this.toastr.error(this.pageLabels[717], "Error", { timeOut: 5000 });//Supplier country is required[717]
      this.loading = false;
    }
    else if (this.supplierDetails.supplierTypeOverseas == undefined && this.supplierDetails.supplierTypeDomestic == undefined) {
      this.toastr.clear();
      this.toastr.error(this.pageLabels[718], "Error", { timeOut: 5000 });//Please select at least one  supplier type![718]
      this.loading = false;
    }
    else {

      this.supplierServiceService.serveManageSupplier(this.supplierDetails)
        .subscribe(
          (data) => {

            if (data.isSuccessful) {
              this.toastr.clear();
              this.toastr.success(data.message, "Success");
              this.loading = false;
              this._location.back();
            }
            else {
              this.toastr.clear();
              this.toastr.error(data.message, "Supplier Error");
              this.loading = false;
            }
          },
          (error) => {
            this.toastr.clear();
            this.toastr.error(error.message, error.name);
            this.loading = false;
          }
        )
    }
  }

  deleteContactDetails(contact: SupplierContactDetail) {
    this.confirmationDialogService.confirm(this.pageLabels[719], this.pageLabels[720])//Contact delete confirmation[719],Do you really want to delete this contact?[720]
      .then(
        (confirmed) => {
          if (confirmed) {

            if (contact.SupplierContactDetailsID > 0) {
              this.supplierServiceService.deleteSupplierContactDetail(contact.SupplierContactDetailsID)
                .then((result) => {
                  if (result.isSuccessful == true) {
                    this.toastr.clear();
                    this.toastr.success(result.message, "Success");
                    for (var i = 0; i <= this.supplierDetails.SupplierContactDetails.length - 1; i++) {
                      if (contact.SupplierContactDetailsID == this.supplierDetails.SupplierContactDetails[i].SupplierContactDetailsID) {
                        this.supplierDetails.SupplierContactDetails.splice(i, 1);
                      }
                    }
                  } else if (result.isSuccessful == false) {
                    this.toastr.clear();
                    this.toastr.error(result.message, "Error", { timeOut: 5000 });
                  }
                  else {
                    this.toastr.clear();
                    this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
                  }
                });
            } else {
              for (var i = 0; i <= this.supplierDetails.SupplierContactDetails.length - 1; i++) {
                if (contact.SupplierContactDetailsID == this.supplierDetails.SupplierContactDetails[i].SupplierContactDetailsID) {
                  this.supplierDetails.SupplierContactDetails.splice(i, 1);
                }
              }
            }
          }

        })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  fillTheFormControls(): Promise<any> {
    return new Promise((data) => {
      this.supplierServiceService.serviceFillControls()
        .subscribe((response) => {
          data(response);
        },
          (error) => {

          }
        )
    })
  }


  //Added by Varsha on 21-10-2019
  //To get all labels and messages from  m_locale_string_resources table.
  async getAllLabelsAndMessages() {
    this.formLabelService.getAllLabelsAndMessages()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.pageLabels = [];
            for (let i = 0; i <= result.data.length - 1; i++) {
              this.pageLabels.push(result.data[i].resourceValue);
            }
          }
          else if (result.isSuccessful == false) {
            this.toastrService.error(result.message, "", { timeOut: 5000 });
          }
          else {
            this.toastrService.error("Failed to connect to the server", "", { timeOut: 5000 });
          }
        } catch (Error) {

        }
      })
      .catch((err) => {

      })
  }

  //Added by Mahesh on 18-11-2019
  //To get country list
  getCoutryList() {
    this.supplierServiceService.getCountryPortList().then(result => {
      if (result.isSuccessful == true) {
        this.countryList = result.data;
      }
      else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
        this.toastr.clear();
        this.toastr.error(result.message, "Error", { timeOut: 5000 });
        localStorage.removeItem('userData');
        this._route.navigate(['/']);
      } else if (result.isSuccessful == false) {
        this.toastr.clear();
        this.toastr.error(result.message, "Error", { timeOut: 5000 });
      } else {
        this.toastr.clear();
        this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
      }

    })
    // this._OriginDestinationPortService.getCountryList().then(result => {
    //   if (result.isSuccessful == true) {
    //     this.countryList = result.data;
    //   }
    //   else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
    //     this.toastr.clear();
    //     this.toastr.error(result.message, "Error", { timeOut: 5000 });
    //     localStorage.removeItem('userData');
    //     this._route.navigate(['/']);
    //   } else if (result.isSuccessful == false) {
    //     this.toastr.clear();
    //     this.toastr.error(result.message, "Error", { timeOut: 5000 });
    //   } else {
    //     this.toastr.clear();
    //     this.toastr.error("Failed to connect to the server", "Error", { timeOut: 5000 });
    //   }
    // })
  }

  //Added by Mahesh on 18-11-2019
  //To get supplier ports list
  getSupplierPorts(selectedCountry) {
    this.supplierDetails.SupplierCountryCode = selectedCountry;
    let supplierId: number = 0;
    //If call is for update
    if (this.supplierDetails.SupplierId > 0) {
      supplierId = this.supplierDetails.SupplierId;
    } else {
      supplierId = 0;
    }

    this.supplierServiceService.getSupplierPorts(selectedCountry, supplierId).then(result => {
      if (result.isSuccessful == true) {

        if (result.data != null && result.data.length > 0) {
          this.portList = result.data;
          console.log("portlist", this.portList)
          for (let i = 0; i < this.portList.length; i++) {
            if (this.portList[i].isSupplierPortIdActive == true) {
              this.portList[i].status = "checked";
            }
          }

        } else {
          this.portList = result.data;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
        }

      }
      else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
        this.toastr.clear();
        this.toastr.error(result.message, "Error", { timeOut: 5000 });
        localStorage.removeItem('userData');
        this._route.navigate(['/']);
      } else if (result.isSuccessful == false) {
        this.toastr.clear();
        this.toastr.error(result.message, "Error", { timeOut: 5000 });
      } else {
        this.toastr.clear();
        this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
      }
    })

  }

  //count:number=1;
  //Added by Mahesh on 18-11-2019
  //To select the port for Supplier
  // selectPort(selectedPortId){
  //

  // if(this.supplierDetails.SupplierPorts.length > 0 && this.count == 1){
  //   this.supplierPortList = this.supplierDetails.SupplierPorts;
  //   this.count += 1;
  // }

  // if(this.supplierPortList.length > 0){
  //   //Check if record already exist in the list
  //   let isRecordExist = false;
  //   let index:number;
  //   for(let i=0;i<this.supplierPortList.length;i++){
  //     if (this.supplierPortList[i].portMasterId == selectedPortId){
  //       isRecordExist = true;
  //       index = i;
  //       break;
  //     }
  //   }

  //   if(isRecordExist){
  //     this.supplierPortList.splice(index,1)
  //   }
  //   else{
  //     this.supplierPortList.push({
  //       portMasterId:selectedPortId,
  //       supplierPortIdCreatedBy:this.userDetails.userId,
  //       supplierPortIdUpdatedBy:this.userDetails.userId,
  //       isSupplierPortIdActive:true,
  //       supplierPortIdCreatedDate:null,
  //       supplierPortIdUpdatedDate:null,
  //       supplierId:0,
  //       supplierPortId:-(this.supplierPortList.length + 1)
  //     })
  //   }

  // }else{
  //   this.supplierPortList.push({
  //     portMasterId:selectedPortId,
  //     supplierPortIdCreatedBy:this.userDetails.userId,
  //     supplierPortIdUpdatedBy:this.userDetails.userId,
  //     isSupplierPortIdActive:true,
  //     supplierPortIdCreatedDate:null,
  //     supplierPortIdUpdatedDate:null,
  //     supplierId:0,
  //     supplierPortId:-(this.supplierPortList.length + 1)
  //   })
  // }

  //}

  selectPort(selectedPortId) {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    for (let i = 0; i < this.portList.length; i++) {
      if (this.portList[i].portMasterId == selectedPortId) {
        if (this.portList[i].isSupplierPortIdActive == false) {
          this.portList[i].isSupplierPortIdActive = true;
          this.portList[i].supplierPortIdUpdatedBy = this.userDetails.userId;
          break;
        } else {
          this.portList[i].isSupplierPortIdActive = false;
          this.portList[i].supplierPortIdUpdatedBy = this.userDetails.userId;
          break;
        }
      }
    }
  }

  // Added by Dnyaneshwari on 12-2-20

  searchCountryPort = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map(term => term.length < 1 ? []
        : this.portNameList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));




  //Added by Dnyaneshwari
  getPorts() {
    this.supplierServiceService.getCountryPortList()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.portList = result.data;
            //console.log("get ports=",this.portList);

            let arrCountryPorts: Array<string> = [];
            result.data.forEach(element => {
              arrCountryPorts.push(element.countryPortName);
            });
            this.portNameList = arrCountryPorts;
          }
          else {
            this.toastr.clear();
            this.toastr.error("Fail to Load", "Error", { timeOut: 5000 })
          }
        }
        catch (Error) {
          alert(Error.message);
        }
      });
  }



  //Added by Dnyaneshwari
  //Updated by Mahesh on 07-04-2020
  selectCountryPort(event: any, portList, supplierPortType: number) {
    if (supplierPortType == 0) {
      //Select Origin
      for (let i = 0; i < portList.length; i++) {
        if (portList[i].countryPortName == event.item) {
          this.originName = portList[i].portName;
          this.originMasterId = portList[i].portMasterId;
          break;
        }
      }
    } else {
      //Select Port
      for (let i = 0; i < portList.length; i++) {
        if (portList[i].countryPortName == event.item) {
          this.portName = portList[i].portName;
          this.portMasterId = portList[i].portMasterId;
          break;
        }
      }
    }

  }

  //Added by Dnyaneshwari
  //Updated by Mahesh on 07-04-2020
  addPorts(supplierPortType: number) {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    if (this.SelectedCountry == undefined) {
      this.toastr.clear();
      this.toastr.error("Please select country before adding Port / Origin", "Error", { timeOut: 5000 });//Please Select Port Name[721]
    }
    else if (this.portMasterId == undefined && supplierPortType == 1) {
      this.toastr.clear();
      this.toastr.error(this.pageLabels[721], "Error", { timeOut: 5000 });//Please Select Port Name[721]
    } else if (this.originMasterId == undefined && supplierPortType == 0) {
      this.toastr.clear();
      this.toastr.error(this.pageLabels[722], "Error", { timeOut: 5000 });//Please Select Origin Name[722]
    } else {

      if (supplierPortType == 0) {
        //For origin
        let isSelected = false;
        for (var i = 0; i < this.selectedPortsListForSupplier.length; i++) {
          if (this.selectedPortsListForSupplier[i].portName == this.originName && this.selectedPortsListForSupplier[i].supplierPortType == false) {
            this.toastr.clear();
            this.toastr.error(this.pageLabels[723], "Error", { timeOut: 5000 });//Origin name already selected[723]
            isSelected = true;
            break;
          }
        }


        if (!isSelected) {
          this.selectedPortsListForSupplier.push({
            supplierPortId: -(this.supplierPortList.length + 1),
            portMasterId: this.originMasterId,
            supplierId: 0,
            supplierPortType: false,
            isSupplierPortIdActive: true,
            portName: this.originName,
            supplierPortIdCreatedBy: this.userDetails.userId,
            supplierPortIdUpdatedBy: this.userDetails.userId,
            supplierPortIdCreatedDate: null,
            supplierPortIdUpdatedDate: null
          })
        }
        this.portList.countryOriginName = "";
      } else {
        //For port
        let isSelected = false;
        for (var i = 0; i < this.selectedPortsListForSupplier.length; i++) {
          if (this.selectedPortsListForSupplier[i].portName == this.portName && this.selectedPortsListForSupplier[i].supplierPortType == true) {
            this.toastr.clear();
            this.toastr.error(this.pageLabels[724], "Error", { timeOut: 5000 });//Port name already selected[724]
            isSelected = true;
            break;
          }
        }

        if (!isSelected) {
          this.selectedPortsListForSupplier.push({
            supplierPortId: -(this.supplierPortList.length + 1),
            portMasterId: this.portMasterId,
            supplierId: 0,
            supplierPortType: supplierPortType == 1 ? true : false,
            isSupplierPortIdActive: true,
            portName: this.portName,
            supplierPortIdCreatedBy: this.userDetails.userId,
            supplierPortIdUpdatedBy: this.userDetails.userId,
            supplierPortIdCreatedDate: null,
            supplierPortIdUpdatedDate: null
          })
        }
        this.portList.countryPortName = "";
      }
    }

  }

  //Added by Mahesh on 07-05-2020, To remove selected port from list
  removePorts(port) {
    if (this.modeFromQS == 'view') {
      this.toastr.clear();
      this.toastr.error("Cannot delete in view mode", "Error", { timeOut: 5000 })
    }
    if (port.supplierPortId > 0) {
      //Api call to remove
      this.loading = true;
      this.supplierServiceService.deleteSupplierPort(port).then(result => {
        if (result.isSuccessful == true) {
          this.loading = false;
          this.toastr.clear();
          this.toastr.success(result.message, "Success", { timeOut: 5000 });
          for (var j = 0; j < this.selectedPortsListForSupplier.length; j++) {
            if (port.portMasterId == this.selectedPortsListForSupplier[j].portMasterId) {
              this.selectedPortsListForSupplier.splice(j, 1);
              break;
            }
          }
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        } else if (result.isSuccessful == false) {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
        } else {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
        }
      })
    } else {
      for (var j = 0; j < this.selectedPortsListForSupplier.length; j++) {
        if (port.portMasterId == this.selectedPortsListForSupplier[j].portMasterId) {
          this.selectedPortsListForSupplier.splice(j, 1);
          break;
        }
      }
    }
  }

  //Added by Mahesh on 09-04-2020
  //To enable or disable domestic or overseas text box
  enableTextbox(textBoxName) {
    if (this.SelectedCountry != undefined) {
      if (textBoxName == 0) {
        this.isOverseasTextCheck = false;
        this.isDomesticTextCheck = true;
        this.isDomesticTextBoxDisabled = this.isDomesticTextBoxDisabled == true ? false : true;
      } else {
        this.isOverseasTextCheck = true;
        this.isDomesticTextCheck = false;
        this.isOverseasTextBoxDisabled = this.isOverseasTextBoxDisabled == true ? false : true;
      }
    } else {
      //alert(this.supplierDetails.supplierTypeDomestic)
      if (textBoxName == 0) {
        this.isOverseasTextCheck = false;
        this.isDomesticTextCheck = true;
      } else {
        this.isOverseasTextCheck = true;
        this.isDomesticTextCheck = false;
      }
      this.isDomesticTextBoxDisabled = true;
      this.isOverseasTextBoxDisabled = true;
    }
  }

  //Added by Mahesh on 04-05-2020
  editSupplierContactDetails(contact: SupplierContactDetail) {
    this.editableRow = contact.SupplierContactDetailsID;
  }

  //Added by Mahesh on 04-05-2020
  saveSupplierContactDetails() {
    this.editableRow = null;
  }


  //Added by Mahesh on 24-05-2020
  //To get list of port/origin
  getPortsOriginList() {
    //alert(this.SelectedCountry)
    this.loading = true;
    this.supplierServiceService.getPortOriginList(this.SelectedCountry)
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.toastr.clear();
            this.toastr.success(result.message, "Success", { timeOut: 5000 })
            this.portList = result.data;
            //console.log("get ports=",this.portList);

            let arrCountryPorts: Array<string> = [];
            result.data.forEach(element => {
              arrCountryPorts.push(element.countryPortName);
            });
            this.portNameList = arrCountryPorts;
            this.loading = false;
            //supplierDetails.supplierTypeOverseas
            //supplierDetails.supplierTypeDomestic
            // if (this.supplierDetails.supplierTypeDomestic || this.supplierDetails.supplierTypeOverseas) {
            //   if (this.isDomesticTextCheck) {
            //     if (this.supplierDetails.supplierTypeDomestic)
            //       this.isDomesticTextBoxDisabled = false;
            //   } else {
            //     if (this.supplierDetails.supplierTypeOverseas)
            //       this.isOverseasTextBoxDisabled = false;
            //   }
            // }
          }
          else {
            this.loading = false;
            this.toastr.clear();
            this.toastr.error(result.message, "Error", { timeOut: 5000 })
          }
        }
        catch (Error) {
          this.loading = false;
          alert(Error.message);
        }
      });
  }

  public getsupplierid() {
    this.route.queryParams.subscribe((data) => {
      this.supplierIDFromQS = data["supplierid"]
    });
    return this.supplierIDFromQS;
  }

  enableEdit() {
    if (this.modeFromQS == 'view') {
      this._route.navigate(['/manageSupplier/'], { queryParams: { supplierid: this.supplierIDFromQS, mode: "edit" } });
    }
    else {
      this._route.navigate(['/manageSupplier/'], { queryParams: { supplierid: this.supplierIDFromQS, mode: "view" } });
    }
  }
}
