import { Component, OnInit } from '@angular/core';
import { TUser, InternalUser, BillToInfo, SoldToInfo, POOtherDetails, CustomerPortOrOrigin } from '../internaluser/user';
import { InternaluserService } from '../internaluser/Shared/internaluser.service';
import { ToastrService } from 'ngx-toastr';
import { ModalWindowService } from '../../shared/modal';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SupplierInternalUserService } from '../supplier/supplier-internal-user/Shared/supplier-internal-user.service';
import { SupplierServiceService } from '../supplier/supplier-service.service';
import { OriginDestinationPortService } from '../../master/origin-destination-ports/shared/origin-destination-port.service';
import { FormlabelsService } from '../../shared/formlabels.service';


@Component({
  selector: 'app-internaluser',
  templateUrl: './internaluser.component.html',
  styleUrls: ['./internaluser.component.css']
})
export class InternaluserComponent implements OnInit {
  public user: any;
  public roleDetails: any = [];
  public statusDetails: any;
  public internalUserListForDisplay: any;
  public deleteUserId: number;
  public disableEmailFeild: boolean = true;
  public isStatusDropdownShown: boolean = false;
  public dtOptions: DataTables.Settings = {};
  public dtOptionsSupplier: DataTables.Settings = {};
  public internalUser: any;
  public Operation: string;

  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loading: boolean = false;
  public showAllUserButton: boolean = false;
  //public userDetails = JSON.parse(localStorage.getItem('userData'));
  public userDetails: any;
  public selectedSupplierName: string;
  public selectedSupplierId: Number;
  public showHideSupplierInternalUserTable: boolean = false;
  //Supplier List for the table
  //public supplierList:Array<supplierCustomisedModel> = [];

  public supplierList: any;
  public supplierInternalUserListForDisplay: any;//Added by Mahesh on 25-05-2020
  OperationTitleName: string;//Added by Mahesh on 25-05-2020
  IsInputWindowVisible: boolean = false;//Added by Mahesh on 25-05-2020
  IsBillToInfoVisible: boolean = false;//Added by Mahesh on 25-05-2020
  IsSoldToInfoVisible: boolean = false;//Added by Mahesh on 25-05-2020
  IsPOOtherDetailsVisible: boolean = false;//Added by Mahesh on 25-05-2020

  billToInfo: BillToInfo;//Added by Mahesh on 25-05-2020
  soldToInfo: SoldToInfo;//Added by Mahesh on 25-05-2020
  poOtherDetails: POOtherDetails;//Added by Mahesh on 25-05-2020
  btnOperation: string;
  SelectedCountry: any;//Added by Mahesh on 01-06-2020
  countryList: any;
  isCountrySelected: boolean = false;
  selectedPortsOriginListForCustomer: CustomerPortOrOrigin[] = [];//Added by Mahesh on 01-06-2020
  portNameList: any = [];//Added by Mahesh on 01-06-2020
  portList: any = [];//Added by Mahesh on 01-06-2020
  portOriginName: string;
  portOriginId: any;
  portBtnOperation: string = "Save";
  isNewPortAdded: boolean = false;
  public CurrencyList: any;
  dateFormat: any;
  public pageLabels: any = [];
  activatePoDetailLink: boolean = false;
  agentList: any;
  public dtOptionsAgent: DataTables.Settings = {};

  constructor(private _route: Router, private route: ActivatedRoute, private internalService: InternaluserService, private toastr: ToastrService,
    private modalService: ModalWindowService, private supplierInternalUserService: SupplierInternalUserService, private supplierServiceService: SupplierServiceService
    , private _OriginDestinationPortService: OriginDestinationPortService, private formLabelService: FormlabelsService
    , private toastrService: ToastrService) {
  }

  ngOnInit() {

    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.dateFormat = this.userDetails.dateFormat + " HH:mm";
    if (this.userDetails == null) {
      this._route.navigate(['/']);
      return;
    } else {

      this.user = new TUser();
      this.internalUser = new InternalUser();
      this.getRoleMaster();
      this.getStatusDetail();

      this.dtOptions = {
        data: this.internalUserListForDisplay,
        pagingType: 'full_numbers',
        scrollY: '130px',
        scrollX: true
      };

      this.dtOptionsSupplier = {
        data: this.supplierList,
        pagingType: 'full_numbers',
        scrollX: true
      };

      this.dtOptionsAgent = {
        data: this.agentList,
        pagingType: 'full_numbers',
        scrollX: true
      };


      this.getInternalUserDetails();

      if (this.activatePoDetailLink) {
        this.showBillToAddressDetails();
      }
    }

    this.billToInfo = new BillToInfo();//Added by Mahesh on 25-05-2020
    this.soldToInfo = new SoldToInfo();//Added by Mahesh on 25-05-2020
    this.poOtherDetails = new POOtherDetails();//Added by Mahesh on 25-05-2020
    this.btnOperation = "Save";
    this.isCountrySelected = false;//Added by Mahesh on 01-06-2020
    this.GetCurrencyList();

    //created by Kunal 11/8/2020
    this.getAllLabelsAndMessages();
    this.route.queryParams.subscribe(data => {
      if (data["showPODetails"] != undefined) {
        var isshowDetails = data["showPODetails"];
        if (isshowDetails == 1) {
          this.activatePoDetailLink = true;
          this.getCustomerAddressess();
        }
      }
    });
  }

  //Added by Varsha on 29-08-2019
  //To Get Role From MRoleMaster Table.
  async getRoleMaster() {


    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let userId: number = this.userDetails.userId;
    this.internalService.getUserRole(userId)
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.roleDetails = result.data;
          }
          else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
            this.toastr.error(result.message, "", { timeOut: 5000 });
            localStorage.removeItem('userData');
            this._route.navigate(['/']);
          } else if (result.isSuccessful == false) {
            this.toastr.error(result.message, "", { timeOut: 5000 });
          } else {
            this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          }
        } catch (Error) {

        }
      })
  }

  //Added by Varsha on 29-08-2019
  //To Get Role From m_cfg_master_detail Table.
  async getStatusDetail() {
    this.internalService.getUserStatus()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.statusDetails = result.data;
          }
          else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
            this.toastr.error(result.message, "", { timeOut: 5000 });
            localStorage.removeItem('userData');
            this._route.navigate(['/']);
          } else if (result.isSuccessful == false) {
            this.toastr.error(result.message, "", { timeOut: 5000 });
          } else {
            this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server
          }
        } catch (Error) {

        }
      })
  }

  //Added by Varsha on 09-09-2019
  //To Add Internal User in TUser Table.
  //f:NgForm
  public POSTInsertInternalUser() {

    this.loading = true;
    this.user.baseUrl = window.location.href;
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.user.loggedInUserId = this.userDetails.userId;
    this.internalService.insertInternalUser(this.user)
      .then((result) => {
        if (result.isSuccessful == true) {
          this.toastr.success(result.message, "", { timeOut: 5000 });
          this.modalService.close('custom-modal-1');
          this.getInternalUserDetails();
          this.loading = false;
          //window.location.reload();
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.toastr.error(result.message, "", { timeOut: 5000 });
          this.loading = false;
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        } else if (result.isSuccessful == false) {
          this.toastr.error(result.message, "", { timeOut: 5000 });
          this.loading = false;
        } else {
          this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          this.loading = false;
        }
      }).catch((result) => {
        this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
        this.loading = false;
      })
  }

  //Added by Varsha on 09-09-2019
  //To get customer deatils for bind to the datatable.
  async getInternalUserDetails() {
    this.loading = true;
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.supplierList = null;
    this.internalService.getInternalUserDetails(this.userDetails.userId)
      .then((result) => {

        if (result.isSuccessful == true) {

          this.internalUserListForDisplay = result.data.internalUserList;
          this.showAllUserButton = result.data.showAllUserButton;

          result.data.supplierList.forEach(element => {

            if (element.supplierStatus == 1) {
              element.supplierStatus = "Active";
            } else if (element.supplierStatus == 0) {
              element.supplierStatus = "Inactive";
            }
            //this.supplierList.push({
            //  VendorIdFromCustomerERP: element.vendorIdFromCustomerERP,
            //  SupplierName: element.supplierName,
            //  SupplierPrimaryContactName: element.supplierPrimaryContactName,
            //  SupplierEmail: element.supplierEmail,
            //  SupplierWebsite: element.supplierWebsite,
            //  //PlannerName: element.plannerName,
            //  //PlannerEmail: element.plannerEmail,
            //  //BuyerName: element.buyerName,
            //  //BuyerEmail: element.buyerEmail,
            //  SupplierID: element.supplierID,
            //  Status: element.supplierStatus 
            //});

          });
          this.supplierList = result.data.supplierList;
          this.agentList = result.data.agentList;
          this.loading = false;
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.toastr.error(result.message, "", { timeOut: 5000 });
          this.loading = false;
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        }
        else if (result.isSuccessful == false) {
          this.toastr.error(this.pageLabels[707], "", { timeOut: 5000 });//No records found to display[707]
          this.loading = false;
        }
        else {
          this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          this.loading = false;
        }
      }).catch((error) => {
        this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
        this.loading = false;
      })
  }

  //Added by Varsha on 09-09-2019
  //To Get Internal User Deatils For Edit UserDetails.
  async getInternalUserDetailsForEdit(userId) {
    this.internalService.getInternalUserDetailsForEdit(userId)
      .then((result) => {

        if (result.isSuccessful == true) {
          this.user = result.data;
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        } else if (result.isSuccessful == false) {
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
        } else {
          this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
          this.loading = false;
        }
      }).catch((result) => {
        this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]

      })
  }

  async getDeleteUserIdAndOpenConfirmPopUp(userId) {
    this.deleteUserId = userId;
  }

  //Added by Varsha on 09-09-2019
  //To Delete Internal User Deatils.
  async deleteInternalUser(userId) {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.internalUser.userId = userId;
    this.internalUser.loggedInUserId = this.userDetails.userId;
    this.internalService.deleteInternalUser(this.internalUser)
      .then((result) => {

        if (result.isSuccessful == true) {
          $("#confirmDeleteUser .close").click()
          this.toastr.success(result.message, "Success", { timeOut: 5000 });
          this.getInternalUserDetails();
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        } else if (result.isSuccessful == false) {
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
        } else {
          this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]

        }
      }).catch((result) => {
        this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]

      })
  }


  //When adding new internal user to show modal windows.
  openModal(id: string) {

    this.Operation = "Add";
    this.user = new TUser();
    this.modalService.open(id);
    if (this.disableEmailFeild == false) {
      this.disableEmailFeild = true;
    }
    if (this.isStatusDropdownShown == true) {
      this.isStatusDropdownShown = false;
    }
  }

  //Added by Varsha on 13-09-2019
  //To Get Internal User Deatils And Bind To The Modal.
  openModalAndBindValue(id: string, userId: number) {
    this.Operation = "Update";
    this.modalService.open(id);
    this.getInternalUserDetailsForEdit(userId);
    if (this.disableEmailFeild == true) {
      this.disableEmailFeild = false;
    }
    if (this.isStatusDropdownShown == false) {
      this.isStatusDropdownShown = true;
    }
  }

  //Close modal popup.
  closeModal(id: string) {
    this.modalService.close(id);
  }

  //Added by Varsha on 17-09-2019
  //To Update Intenal User Status.
  async updateInternalUserStatus(userId) {
    this.internalUser.userId = userId;
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.internalUser.loggedInUserId = this.userDetails.userId;
    this.internalService.enableInternalUser(this.internalUser)
      .then((result) => {

        if (result.isSuccessful == true) {
          this.getInternalUserDetails();
          this.toastr.success(result.message, "Success", { timeOut: 5000 });
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        }
        else if (result.isSuccessful == false) {
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
        }
        else {
          this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]

        }
      }).catch((result) => {
        this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
      });
  }

  async showAllUsersExceptLoggedIn() {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.internalService.serviceShowAllUsersExceptLoggedIn(this.userDetails).subscribe((response: any) => {

      if (response.isSuccessful == true) {
        console.log(response.data);
        this.internalUserListForDisplay = response.data;
      }
      else if (response.isSuccessful == false && response.consoleMessage == "UNAUTH") {
        this.toastr.error(response.message, "", { timeOut: 5000 });
        localStorage.removeItem('userData');
        this._route.navigate(['/']);
      } else if (response.isSuccessful == false) {
        this.toastr.error(response.message, "", { timeOut: 5000 });
      } else {
        this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
      }
    });
  }

  GoToManageSupplier(supplier: any) {
    this._route.navigate(['/manageSupplier/'], { queryParams: { supplierid: supplier.supplierID, mode: "view" } });
    //this.router.navigate(['/customers'], { queryParams: { order: 'popular', 'price-range': 'not-cheap' } });

  }

  //Added by Mahesh on 18-10-2019
  //To show selected supplier's intenal users.
  async viewSupplierInternalUser(supplierName, supplierId) {
    //alert('You clicked '+supplierName +" "+supplierId)
    this.showHideSupplierInternalUserTable = true;
    this.selectedSupplierName = supplierName;
    this.selectedSupplierId = supplierId;
    let isSupplier = false;
    this.getSupplierInternalUserDetails(supplierId, isSupplier);
    //$("#headingOne").focus();
    //document.body.focus('headingOne');

  }

  //Added by Mahesh on 18-10-2019
  //To show all intenal users except deleted users and logged in user.
  async getSupplierInternalUserDetails(supplierId, isSupplier) {
    this.loading = true;

    this.supplierInternalUserService.getSupplierInternalUserDetails(supplierId, isSupplier)
      .then((response: any) => {
        if (response.isSuccessful == true) {

          this.supplierInternalUserListForDisplay = response.data.supplierInternalUserList;
          this.showAllUserButton = response.data.showAllUserButton;
          this.roleDetails = response.data.roleDetails;
          this.loading = false;
        }
        else if (response.isSuccessful == false && response.consoleMessage == "UNAUTH") {
          this.toastr.error(response.message, "", { timeOut: 5000 });
          this.loading = false;
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        }
        else if (response.isSuccessful == false) {
          this.toastr.error(this.pageLabels[707], "", { timeOut: 5000 });//No records found to display[707]
          this.loading = false;
        }
        else {
          this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
          this.loading = false;
        }
      }).catch((error) => {
        this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
        this.loading = false;
      })
  }

  //Added by Mahesh on 17-10-2019
  //To Update Supplier's Intenal User Status.
  async updateSupplierInternalUserStatus(userId) {

    this.internalUser.userId = userId;
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    this.internalUser.loggedInUserId = this.userDetails.userId;
    this.internalService.enableInternalUser(this.internalUser)
      .then((result) => {
        if (result.isSuccessful == true) {
          let isSupplier = false;
          this.getSupplierInternalUserDetails(this.selectedSupplierId, isSupplier);
          this.toastr.success(result.message, "", { timeOut: 5000 });
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.toastr.error(result.message, "", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        }
        else if (result.isSuccessful == false) {
          this.toastr.error(result.message, "", { timeOut: 5000 });
        }
        else {
          this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
        }
      }).catch((result) => {
        this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
      });
  }

  //Added by Mahesh on 21-10-2019
  //To close the table view of supplier's internal user
  async closeViewSupplierInternalUsetTable() {
    this.showHideSupplierInternalUserTable = false;
  }

  //Added by Mahesh on 22-10-2019
  //To update the supplier's status to active or inactive 
  async enableOrDissableSupplier(supplierId) {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let loggedInUserId = this.userDetails.userId;
    this.internalService.enableOrDissableSupplier(supplierId, loggedInUserId)
      .then((result) => {
        if (result.isSuccessful == true) {
          this.getInternalUserDetails();
          this.toastr.success(result.message, "", { timeOut: 5000 });
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.toastr.error(result.message, "", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        }
        else if (result.isSuccessful == false) {
          this.toastr.error(result.message, "", { timeOut: 5000 });
        }
        else {
          this.toastr.error(this.pageLabels[58], "", { timeOut: 5000 });//Failed to connect to the server[58]
        }
      });
  }

  //Added by Mahesh on 25-05-2020
  //To get the bill to address, sold to address po other details 
  getCustomerAddressess() {
    this.loading = true;
    this.internalService.getCustomerAddressess()
      .then((result) => {
        if (result.isSuccessful == true) {
          this.loading = false;
          this.billToInfo = result.data.billToInfo;
          this.soldToInfo = result.data.soldToInfo;
          this.poOtherDetails = result.data.poOtherDetails;
          this.showBillToAddressDetails()
          this.toastr.clear();
          this.toastr.success(result.message, "Success", { timeOut: 5000 });
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        }
        else if (result.isSuccessful == false) {
          this.loading = false;
          this.toastr.clear();
          this.toastr.warning(result.message, "Alert", { timeOut: 5000 });
          this.showBillToAddressDetails();
        }
        else {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
        }
      })
  }

  //Added by Mahesh on 25-05-2020
  //To show the bill to address
  showBillToAddressDetails() {
    this.OperationTitleName = "Bill To Information";
    if (this.billToInfo != null && this.billToInfo.billedToAddressLine1 != null) {
      this.btnOperation = "Update";
    }
    else {
      this.billToInfo = new BillToInfo();
      this.btnOperation = "Save";
    }
    this.IsInputWindowVisible = true;
    this.IsBillToInfoVisible = true;
    this.IsSoldToInfoVisible = false;
    this.IsPOOtherDetailsVisible = false;
  }

  //Added by Mahesh on 25-05-2020
  //To show the sold to address
  showSoldToAddressDetails() {
    this.OperationTitleName = "Sold To Information";
    if (this.soldToInfo != null && this.soldToInfo.soldToAddressLine1 != null) {
      this.btnOperation = "Update";
    }
    else {
      this.soldToInfo = new SoldToInfo();
      this.btnOperation = "Save";
    }
    this.IsInputWindowVisible = true;
    this.IsBillToInfoVisible = false;
    this.IsSoldToInfoVisible = true;
    this.IsPOOtherDetailsVisible = false;
  }

  //Added by Mahesh on 25-05-2020
  //To show thepo other details
  showPoInfoDetails() {
    this.OperationTitleName = "Other Details";
    if (this.poOtherDetails != null && this.poOtherDetails.poPaymentTerms != null) {
      this.btnOperation = "Update";
    }
    else {
      this.poOtherDetails = new POOtherDetails();
      this.btnOperation = "Save";
    }
    this.IsInputWindowVisible = true;
    this.IsBillToInfoVisible = false;
    this.IsSoldToInfoVisible = false;
    this.IsPOOtherDetailsVisible = true;
  }

  //Added by Mahesh on 25-05-2020
  //To close the input windows
  closeInputWindow() {
    this.IsInputWindowVisible = false;
    this.IsBillToInfoVisible = false;
    this.IsSoldToInfoVisible = false;
    this.IsPOOtherDetailsVisible = false;
  }

  //Added by Mahesh on 25-05-2020
  //To add or update bill to info
  addOrUpdateBillToInfo() {
    this.loading = true;
    if (this.billToInfo.customerBilledToInformationId > 0) {
      this.billToInfo.customerBilledToInformationUpdatedBy = this.userDetails.userId;
    } else {
      this.billToInfo.customerBilledToInformationUpdatedBy = this.userDetails.userId;
      this.billToInfo.customerBilledToInformationCreatedBy = this.userDetails.userId;
    }

    this.internalService.addOrUpdateBillToInfo(this.billToInfo)
      .then((result) => {
        if (result.isSuccessful == true) {
          this.loading = false;
          this.billToInfo = result.data;
          this.toastr.clear();
          this.toastr.success(result.message, "Success", { timeOut: 5000 });

        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        }
        else if (result.isSuccessful == false) {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
        }
        else {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
        }
      })
  }

  //Added by Mahesh on 25-05-2020
  //To add or update sold to info
  addOrUpdateSoldToInfo() {
    this.loading = true;
    if (this.soldToInfo.customerSoldToInformationId > 0) {
      this.soldToInfo.customerSoldToInformationUpdatedBy = this.userDetails.userId;
    } else {
      this.soldToInfo.customerSoldToInformationUpdatedBy = this.userDetails.userId;
      this.soldToInfo.customerSoldToInformationCreatedBy = this.userDetails.userId;
    }

    this.internalService.addOrUpdateSoldToInfo(this.soldToInfo)
      .then((result) => {
        if (result.isSuccessful == true) {
          this.loading = false;
          this.soldToInfo = result.data;
          this.toastr.clear();
          this.toastr.success(result.message, "Success", { timeOut: 5000 });

        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        }
        else if (result.isSuccessful == false) {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
        }
        else {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
        }
      })
  }

  //Added by Mahesh on 25-05-2020
  //To add or update po other details
  addOrUpdatePOOtherDetails() {
    this.loading = true;
    if (this.poOtherDetails.customerPurchaseOrderInformationId > 0) {
      this.poOtherDetails.customerPurchaseOrderInformationUpdatedBy = this.userDetails.userId;
    } else {
      this.poOtherDetails.customerPurchaseOrderInformationUpdatedBy = this.userDetails.userId;
      this.poOtherDetails.customerPurchaseOrderInformationCreatedBy = this.userDetails.userId;
    }

    this.internalService.addOrUpdatePOOtherDetails(this.poOtherDetails)
      .then((result) => {
        if (result.isSuccessful == true) {
          this.loading = false;
          this.poOtherDetails = result.data;
          this.toastr.clear();
          this.toastr.success(result.message, "Success", { timeOut: 5000 });
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        }
        else if (result.isSuccessful == false) {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
        }
        else {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
        }
      })
  }

  //Added by Mahesh on 01-06-2020
  //To get list of port / origin
  getPortsOriginList() {
    //alert(this.SelectedCountry)
    this.supplierServiceService.getPortOriginList(this.SelectedCountry)
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.toastr.clear();
            if (result.data.length == 0) {
              this.toastr.success(this.pageLabels[708], "Success", { timeOut: 5000 })//Please add/activate the port in port master[708]
            } else {
              this.toastr.success(result.message, "Success", { timeOut: 5000 })
              this.portList = result.data;
              this.isCountrySelected = true;

              let arrCountryPorts: Array<string> = [];
              result.data.forEach(element => {
                arrCountryPorts.push(element.countryPortName);
              });
              this.portNameList = arrCountryPorts;
            }

          }
          else {
            this.toastr.clear();
            this.toastr.error(result.message, "Error", { timeOut: 5000 })
          }
        }
        catch (Error) {
          alert(Error.message);
        }
      });
  }

  //Added by Mahesh on 01-06-2020
  //To get country list
  getCoutryList() {
    this.loading = true;
    this.countryList = null;
    this.supplierServiceService.getCountryPortList().then(result => {
      if (result.isSuccessful == true) {
        this.countryList = result.data;
      }
      else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
        this.toastr.clear();
        this.toastr.error(result.message, "Error", { timeOut: 5000 });
        localStorage.removeItem('userData');
        this._route.navigate(['/']);
      } else if (result.isSuccessful == false) {
        this.toastr.clear();
        this.toastr.error(result.message, "Error", { timeOut: 5000 });
      } else {
        this.toastr.clear();
        this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
      }
    })

    this.getSelectedPortsOriginsForCustomerToDisplay();
  }

  //Added by Mahesh on 01-06-2020
  //To add ports in a list
  addPorts() {
    this.userDetails = JSON.parse(localStorage.getItem('userData'));
    let isSelected = false;
    for (var i = 0; i < this.selectedPortsOriginListForCustomer.length; i++) {
      if (this.selectedPortsOriginListForCustomer[i].portName == this.portOriginName) {
        this.toastr.clear();
        this.toastr.error(this.pageLabels[709], "Error", { timeOut: 5000 });//Port or Origin name already selected.[709]
        isSelected = true;
        break;
      }
    }

    if (!isSelected && this.portOriginName != undefined) {
      this.isNewPortAdded = true;
      this.selectedPortsOriginListForCustomer.push({
        portsForCustomerId: -1,
        portId: this.portOriginId,
        customerId: 0,
        portName: this.portOriginName,
        portForCustomerCreatedBy: this.userDetails.userId,
        portForCustomerUpdatedBy: this.userDetails.userId,
        portForCustomerCreatedDate: null,
        portForCustomerUpdatedDate: null
      })
    } else {
      this.toastr.clear();
      this.toastr.error(this.pageLabels[708], "Error", { timeOut: 5000 });//Please add/activate the port in port master[708]
    }
    this.portList.countryOriginName = "";
  }

  //Added by Mahesh on 01-06-2020
  //For NGB type head
  searchCountryPort = (text$: Observable<string>) =>
    text$
      .debounceTime(200)
      .distinctUntilChanged()
      .map(term => term.length < 1 ? []
        : this.portNameList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10));

  //Added by Mahesh on 01-06-2020
  //To select the filtered port / origin
  selectCountryPort(event: any, portList) {

    for (let i = 0; i < portList.length; i++) {
      if (portList[i].countryPortName == event.item) {
        this.portOriginName = portList[i].portName;
        this.portOriginId = portList[i].portMasterId;
        break;
      }
    }
  }

  //Added by Mahesh on 01-06-2020
  //To save the selected port / origin
  insertOrUpdateSelectedPortsForCustomer() {
    this.loading = true;
    console.log(this.selectedPortsOriginListForCustomer);
    this.internalService.insertOrUpdateSelectedPortsOriginForCustomer(this.selectedPortsOriginListForCustomer)
      .then((result) => {
        if (result.isSuccessful == true) {
          this.loading = false;
          this.isNewPortAdded = false;
          this.portOriginName = "";
          this.portOriginId = undefined;
          this.SelectedCountry = null;
          this.isCountrySelected = false;
          this.toastr.clear();
          this.toastr.success(result.message, "Success", { timeOut: 5000 });
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        }
        else if (result.isSuccessful == false) {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
        }
        else {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
        }
      })
  }

  //Added by Mahesh on 01-06-2020
  //To get the selected port / origin for display
  getSelectedPortsOriginsForCustomerToDisplay() {
    this.loading = true;
    this.internalService.getSelectedPortsOriginsForCustomerToDisplay()
      .then((result) => {
        if (result.isSuccessful == true) {
          this.loading = false;
          this.selectedPortsOriginListForCustomer = result.data;
          if (this.selectedPortsOriginListForCustomer != null && this.selectedPortsOriginListForCustomer.length > 0) {
            this.portBtnOperation = "Update";
          } else {
            this.portBtnOperation = "Save";
          }
          this.toastr.clear();
          this.toastr.success(result.message, "Success", { timeOut: 5000 });
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        }
        else if (result.isSuccessful == false) {
          this.loading = false;
          this.toastr.clear();
          this.toastr.warning(result.message, "Alert", { timeOut: 5000 });
        }
        else {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
        }
      })
  }

  //Added by Mahesh on 07-05-2020, To remove selected port from list
  removePorts(port) {
    if (port.portsForCustomerId > 0) {
      //Api call to remove
      this.loading = true;
      this.internalService.deleteCustomerPortOrigin(port.portsForCustomerId).then(result => {
        if (result.isSuccessful == true) {
          this.loading = false;
          this.toastr.clear();
          this.toastr.success(result.message, "Success", { timeOut: 5000 });
          for (var j = 0; j < this.selectedPortsOriginListForCustomer.length; j++) {
            if (port.portId == this.selectedPortsOriginListForCustomer[j].portId) {
              this.selectedPortsOriginListForCustomer.splice(j, 1);
              break;
            }
          }
        }
        else if (result.isSuccessful == false && result.consoleMessage == "UNAUTH") {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
          localStorage.removeItem('userData');
          this._route.navigate(['/']);
        } else if (result.isSuccessful == false) {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(result.message, "Error", { timeOut: 5000 });
        } else {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error(this.pageLabels[58], "Error", { timeOut: 5000 });//Failed to connect to the server[58]
        }
      })
    } else {
      for (var j = 0; j < this.selectedPortsOriginListForCustomer.length; j++) {
        if (port.portId == this.selectedPortsOriginListForCustomer[j].portId) {
          this.selectedPortsOriginListForCustomer.splice(j, 1);
          break;
        }
      }
    }
  }

  //added by Omkar 39th June 2020
  GetCurrencyList() {
    this.internalService.getCurrencyList()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {

            this.CurrencyList = result.data;
          }
        } catch (error) {

        }
      })
  }

  //added by Kunal on 11/8/2020 get labels from db mlocalstring
  async getAllLabelsAndMessages() {
    this.formLabelService.getAllLabelsAndMessages()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.pageLabels = [];
            for (let i = 0; i <= result.data.length - 1; i++) {
              this.pageLabels.push(result.data[i].resourceValue);
            }
          }
          else if (result.isSuccessful == false) {
            this.toastrService.error(result.message, "", { timeOut: 5000 });
          }
          else {
            this.toastrService.error("Failed to connect to the server", "", { timeOut: 5000 });
          }
        } catch (Error) {

        }
      })
      .catch((err) => {

      })
  }

  GoToManageAgent(agentId: any) {
    this._route.navigate(['/manageAgent/'], { queryParams: { AgentId: agentId, mode: "view" } });
  }

  NavigateToEditAgent(agentId: any) {
    this._route.navigate(['/manageAgent/'], { queryParams: { AgentId: agentId, mode: "edit" } });
  }

}
